import React, {useState} from 'react';  
import { Grid, Typography} from '@mui/material';  
import { useTranslation } from 'react-i18next';   
import Rating from '@mui/material/Rating'; 
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import api from '../services/api';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom';




const Freelance_commentaire = (props) => {
    const { t, i18n } = useTranslation(); 
    const userData = JSON.parse(window.localStorage.getItem('userData')); 
    const navigate = useNavigate();
    
    
    const valider_commentaire= (e) => { 
        e.preventDefault(); 
        Swal.fire ({title: 'Wait ...', showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading ()
            }
        })

        const formData = {'id': props.idCommentaire, }
        
        api.post('/api/activer_Commentaire', formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => { 
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => {
        console.log(error);
        }); 

    };

    const desactiver_commentaire = (e) => {
        e.preventDefault(); 
        Swal.fire ({title: 'Wait ...', showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading ()
            }
        })

        const formData = {'id': props.idCommentaire, }
        
        api.post('/api/desactiver_Commentaire', formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => { 
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => {
        console.log(error);
        }); 
    }
    
    const supprimer_commentaire = (e) => {
        e.preventDefault(); 
        Swal.fire ({title: 'Wait ...', showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading ()
            }
        })

        const formData = {'id': props.idCommentaire}
        
        api.get(`/api/supprimer_Commentaire/${props.idCommentaire}`, formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => {  
            console.log(response.data)
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => {
        console.log(error);
        }); 
     }
    return (  
        <Grid container spacing={2}>  
            <Grid item sm={8} sx={{display:'flex'}}>
                <img src='/img/default.png' className='imgCommentaire'/><Typography sx={{fontWeight:'900', fontSize:17, p:1}}> {props.nom}<Typography>{props.email}</Typography></Typography>                                                
            </Grid>
            <Grid item sm={4} >
                <Rating
                    name="simple-controlled"
                    value={props.etoile}
                    readOnly
                    sx={{mb:1}}
                />
            </Grid>
            <Grid item sm={12}>
                <Typography> {props.commentaire}</Typography>
                
            </Grid>
            <Grid item sm={12}>
                {(userData != null && userData.user.is_admin == 1 && userData.signedIn && props.valider_commentaire == 0)  ? 
                        <Box sx={{p:2}}>
                            <input hidden  value={props.idCommentaire}  />
                            <Button variant="contained" color="success" onClick={valider_commentaire} sx={{m:2}}>Valider</Button> 
                            <Button variant="contained" color="error" onClick={supprimer_commentaire} sx={{m:2}}>Supprimer</Button>
                        </Box>
                :(userData != null && userData.user.is_admin == 1 && userData.signedIn && props.valider_commentaire == 1) ?
                    <Box sx={{p:2}}>
                            <input hidden  value={props.idCommentaire}  />
                            <Button variant="contained" color="warning" onClick={desactiver_commentaire}>Desactiver</Button> 
                            <Button variant="contained" color="error" onClick={supprimer_commentaire} sx={{m:2}}>Supprimer</Button>

                    </Box>
                :''

                } 
            </Grid>
            
        </Grid>  
           
    );
}

export default Freelance_commentaire;
