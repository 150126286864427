import React, {useState} from 'react'; 
import Container from '@mui/material/Container'; 
import { Grid, Typography, Rating } from '@mui/material';  
import { useTranslation } from 'react-i18next';   
import VisibilityIcon from '@mui/icons-material/Visibility';
import CommentIcon from '@mui/icons-material/Comment';



const Notes = (props) => {
    const { t, i18n } = useTranslation(); 
    // const [valueRatings, setValueRatings] = useState(0);
   

    return ( 
        <Container> 
           <Grid container  columnSpacing={{xs: 6, }} sx={{textAlign:'center'}} >
                <Grid item md={3} sx={{display:'-webkit-box',}}>
                    <CommentIcon sx={{fontSize:'.8rem', verticalAlign:'middle'}} /><Typography className='typo-commentaire-cout' sx={{fontSize:'.8rem'}}>{props.count_commentaire}</Typography>
                </Grid>
                <Grid item md={3} sx={{display:'-webkit-box',}}>
                    <VisibilityIcon sx={{fontSize:'.8rem', verticalAlign:'middle'}}/><Typography className='typo-commentaire-cout' sx={{fontSize:'.8rem'}}>{props.count_Vsbtly}</Typography>
                </Grid>
                <Grid item md={4}>
                    <Rating
                        name="simple-controlled"
                        value={props.valueRating} 
                        readOnly 
                        sx={{mb:1, fontSize:20}} 
                    />
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>  
        </Container>
    );
}

export default Notes;
