import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';  
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import api from '../services/api'; 
import {useNavigate, Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'; 

const Login = (props) => {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const navigate = useNavigate();  


    useEffect(() => { 
        const userData = JSON.parse(window.localStorage.getItem('userData'));
        if (userData != null && userData.user.is_admin == 1 && userData.signedIn) {
          navigate('/rim');
        }
        else if(userData != null && userData.user.type == 0 && userData.signedIn){
          navigate('/profil');
        }
        else if(userData != null && userData.user.type == 1 && userData.signedIn){
          navigate(`/publier/${userData.code}`);
        }
        i18n.language == 'fr' ? document.title = 'Login - ' + t('title')  : document.title = 'تسجيل الدخول - ' + t('title') 

        
    },[]);


    const handleSubmit = (e) => {
        e.preventDefault(); 
        api.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            api.post(`api/login`, formData, {
                headers: {
                    'Accept': 'application/json'
                }
            }).then(({data}) => { 
                console.log(data.societe)
                const user = data.user;
                const token = data.token;
                const idFreelance = data.idFree;
                const portof = data.portof;
                const services = data.offres_freelance;
                if(data.societe != null){ 
                  const code = data.societe.code;
                  window.localStorage.setItem('userData', JSON.stringify({signedIn: true, user, token, idFreelance, portof, code, services}));
                }else{
                  window.localStorage.setItem('userData', JSON.stringify({signedIn: true, user, token, idFreelance, portof, services}));
                }
                navigate('/profil'); 
            }).catch(({response}) => {
                if (response.status == 422) {
                    console.log(response); 
                } else {
                    console.log(response); 
                    i18n.language == 'fr' ? Swal.fire({icon: "error", text: response.data.messagefr,confirmButtonText: t('ok')}) :  Swal.fire({icon: "error", text: response.data.messageAr,confirmButtonText: t('ok')})
                }
            }) 
        });


    }



    return (


        <Container>   
     
     <Paper sx={{p: 7, mt:3}}>  
        <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 3,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#18689f'}}>
              {t('login')}
        </Typography>  
         
         

        <Grid container spacing={2} >
          <Grid item md={12} sx={{width : '100%'}}>
            <TextField id="outlined-uncontrolled" 
              label={t('email')}
              variant="outlined"
              sx={{width : '100%', direction:'ltr'}} 
              value={email}
              onChange={(event) => {setEmail(event.target.value)}} 
            />
          </Grid>

          <Grid item md={12} sx={{width : '100%'}}>
            <TextField id="outlined-uncontrolled" 
              label={t('password')}
              variant="outlined"
              sx={{width : '100%'}}
              value={password}
              type="password"
              onChange={(event) => {setPassword(event.target.value)}} 
            />
          </Grid>

          
          <Grid item md={12} sx={{width : '100%', display:'flex', justifyContent : 'center'}}>
            
            <Button  variant="contained" className='btn'  onClick={handleSubmit}>{t('log-in')} </Button>
            <Typography sx={{m: '10px 10px 0px 10px', color:'#18689f', fontSize:13, textDecorationLine:'none'}} component={Link} to="/resetPassword">{t('pass_oblier')}</Typography>
            
          </Grid>

          <Grid item md={12} sx={{width : '100%', textAlign : 'center'}}>
            
            <Typography>{t('have_a_compte')} <Link to="/inscrire" style={{textDecorationLine:'none'}}><span style={{color:'red', fontWeight:'600'}}>{t('registre')}</span> </Link> </Typography>
            
          </Grid>
          

        </Grid>
      </Paper>  
   </Container>
    );
}

export default Login;
