import Axios from 'axios'

const api = Axios.create({

    // baseURL: 'http://localhost/Link/public/',
    baseURL: 'https://link.mr/back/',

    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
    //withCredentials: true,
})

export default api
