import {BrowserRouter as Router, Routes, Route,} from 'react-router-dom';
import Accuiel from './pages/accuiel';
import Navbar from './components/Navbar';
import Avis_publier from './pages/avis_publier';
import Avis_detail from './pages/avis_detail_fo_bo';
import Freelances from './pages/freelances_fo';
import Freelance_detail_fo from './pages/freelance_detail_fo';
import Freelance_inscrire from './pages/freelance_inscrire';
import Freelance_projet_detail from './pages/freelance_projet_detail';
import Freelance_mon_profile from './pages/freelance_mon_profile';
import Freelance_complet_profile from './pages/freelance_complet_profile';
import Freelance_projet_ajout from './pages/freelance_projet_ajout';
import Freelance_edit_profile from './pages/freelance_edit_profile';
import Avis_edit from './pages/avis_edit';
import Freelance_projet_edit from './pages/freelance_projet_edit';
import Admin from './pages/admin_accueil';
import Login from './pages/Login';  
import ResetPassword from './pages/resetPassword';
import RenitialiserPassword from './pages/renitialiserPassword';
import Avis_list_bo from './pages/avis_list_bo';
import Freelances_bo from './pages/freelances_bo';
import SociteProfile from './pages/societe_profile';
import Sa3dpublierAvis from './pages/Sa3dpublier_avis';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Freelance_service_detait from './pages/freelance_service_detail';
import Freelance_service_edit from './pages/freelance_service_edit';
import Freelance__service_ajout from './pages/freelance_service_ajouter';

function App() { 

    const theme = createTheme({ 
        typography: {
            allVariants: {
            fontFamily: 'monospace',
            color:'black',
            },
        },
    });

    const arabictheme = createTheme({  
        typography: {
            allVariants: {
            fontFamily: 'Almarai',
            color:'black',
            },
        },
        
         
        MuiButton: {
        styleOverrides: {
            root: {
            fontFamily: ['Almarai'].join(","),
            },
        },
        },
        
    });

   

   const {i18n } = useTranslation();
   localStorage.setItem("i18nextLng", i18n.language);
   document.body.dir = i18n.dir();

    return (
        <ThemeProvider theme={i18n.language === 'fr' ? theme : arabictheme}> 
         
            <Router >   
                <Navbar/> 
                <Routes>
                    <Route exact path='/'
                        element={<Accuiel/>}/> 

                    <Route path='/publier/:code'
                        element={<Avis_publier/>}/>

                    <Route path='avis/:slug/:id'
                        element={<Avis_detail/>}/>

                    <Route path='/freelances'
                        element={<Freelances/>}/>

                    <Route path='/freelance/:id'
                        element={<Freelance_detail_fo/>}/>   

                    <Route path='/inscrire'
                        element={<Freelance_inscrire/>}/> 

                    <Route path='/projet/:id'
                        element={<Freelance_projet_detail/>}/> 

                    <Route path='/service/:id'
                        element={<Freelance_service_detait/>}/> 

                    <Route path='/profil'
                        element={<Freelance_mon_profile/>}/>  

                    <Route path='/completerProfile/:code'
                        element={<Freelance_complet_profile/>}/>  

                    <Route path='/ajouterProject'
                        element={<Freelance_projet_ajout/>}/>   

                    <Route path='/ajouterService'
                        element={<Freelance__service_ajout/>}/>                 

                    <Route path='/modifierAvis/:id'
                        element={<Avis_edit/>}/> 

                    <Route path='/modifierProject/:id'
                        element={<Freelance_projet_edit/>}/> 

                    <Route path='/modifierService/:id'
                        element={<Freelance_service_edit/>}/> 


                    <Route path='/login'
                        element={<Login/> }/>

                    <Route path='/rim'
                        element={<Admin/> }/>  

                    <Route path='/listTousAvis'
                        element={< Avis_list_bo/> }/> 

                    <Route path='/list_Freelance'
                        element={<Freelances_bo /> }/>            

                    <Route path='/editeProfile/:id'
                        element={<Freelance_edit_profile/>}/>   

                    <Route path='/resetPassword'
                        element={<ResetPassword/>}/> 

                    <Route path='/renitialiserPassword/:token'
                        element={<RenitialiserPassword/>}/>

                    <Route path='/sociteProfile'
                        element={<SociteProfile/>}/> 

                    <Route path='/sa3dpublierAvis'
                        element={<Sa3dpublierAvis/>}/>   

                    <Route path="*" element={<Accuiel />} />    
                     
                </Routes>  
            </Router>     
        </ThemeProvider>


    );
}

export default App;
