import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';  
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import api from '../services/api'; 
import {useNavigate} from 'react-router-dom'; 
import Swal from 'sweetalert2';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';

export default function Freelance_projet_ajout() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [freelance, setFreelance] = useState({});
    const [freelanceId, setFreelanceId] = useState();
    const [titre, setTitre] = useState('')
    const [categorie, setCategorie] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const ud = JSON.parse(window.localStorage.getItem('userData'));

    const changeHandler = (event) => {
        setImage(event.target.files[0]);
    };
     

    useEffect(() => {   
        handleGetFreelancere();
        i18n.language == 'fr' ? document.title = 'Ajouter projet - ' + t('title')  : document.title = 'أضف عمل - ' + t('title') ;
         
    },[]);

  
    const handleGetFreelancere = () => {   
        if(ud != null) {
            const email = ud.user.email;
            api.get(`/api/profile/${email}`, {
                headers: {
                "Accept": "application/json", 
                }
            }).then(response => {   
                setFreelance(response.data.freelance)    
                setFreelanceId(response.data.freelance.id)    
            }).catch(error => {
                console.log(error); 
            });
        } 
        else{
            navigate('/')
        }
     
    };  

  
    const handleSaveProject = (e) => { 
        e.preventDefault();  
        Swal.fire ({
            title: t('attender'),
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        })

        api.get('/sanctum/csrf-cookie').then((response) => {
            if (ud != null) {
                 const form = new FormData(); 
                 form.append('freelanceId', freelanceId);
                 form.append('titre', titre);
                 form.append('categorie', categorie);
                 form.append('description', description);
                 form.append('image', image);

                api.post( `/api/addProject`, form).then(response => { 
                    console.log(response.data);
                    const prj = response.data.portofolio;
                    const portof = ud.portof;
                    portof.push(prj);
                    ud.portof=portof;
                    window.localStorage.setItem('userData', JSON.stringify(ud)); 

                    i18n.language == 'fr' ? Swal.fire({icon: "success", text: response.data.messagefr, confirmButtonText: t('ok')}) :  Swal.fire({icon: "success", text: response.data.messageAr,confirmButtonText: t('ok')})
                    navigate(`/profil`)
                }).catch(error => {
                console.log(error);
                });
            }

            }); 
    };
    
    

  return (
    <Container> 
        <Paper sx={{p: 2, mt: 3}}>
            <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 1,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#1976d2'}}>
                {t('addProject')}
            </Typography>                         
        </Paper> 
 
        <Paper sx={{p: 4}}>
            <form onSubmit={handleSaveProject} >
                <Grid container spacing={2} >
                    <Grid item md={12} sx={{width : '100%'}}>
                        <input type="hidden" name='freelanceId' value={freelanceId} onChange={(event) => {setFreelanceId(event.target.value)}} />
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                        <TextField id="outlined-uncontrolled"
                            required 
                            label={t('titreP')}
                            variant="outlined"
                            sx={{width : '100%'}} 
                            value={titre}
                            onChange={(event) => {setTitre(event.target.value)}}
                            />
                            <Typography sx={{fontSize:13}}>{t('message_titre_projet')} </Typography>
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t('domaine')}</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={t('domaine')} 
                                    value={categorie}
                                    onChange={(event) => {setCategorie(event.target.value)}} 
                                    >
                                        <MenuItem value='Services_commerciaux'>{t('Services_commerciaux')}</MenuItem>
                                        <MenuItem value='Développement_web'>{t('Développement_web')}</MenuItem>
                                        <MenuItem value='Design'>{t('Design')}</MenuItem>
                                        <MenuItem value='Ventes'>{t('Ventes')}</MenuItem>
                                        <MenuItem value='Redaction'>{t('Redaction')}</MenuItem>
                                        <MenuItem value='Services_comptables'>{t('Services_comptables')}</MenuItem>
                                        <MenuItem value='Formation'>{t('Formation')}</MenuItem>
                                        <MenuItem value='Architecture'>{t('Architecture')}</MenuItem>
                                        <MenuItem value='Autres'>{t('Autres')}</MenuItem>
                                </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={12} sx={{width : '100%'}}>
                        <TextareaAutosize
                            required
                            maxRows={40}
                            aria-label="maximum height"
                            placeholder={t('message_titre_desciption')} 
                            style={{ width: '100%', height : 100 }} 
                            value={description}
                            onChange={(event) => {setDescription(event.target.value)}} 
                            />  
                    </Grid>

                    <Grid item md={12} sx={{width : '100%'}}>
                            <Typography variant="body2" sx={{ mt : 0.5, mb :1, fontWeight : 'bold',  }}>{t('image')}</Typography>
                        
                        <input type="file" accept="image/*" onChange={changeHandler}/>  
                            <Typography variant="body2" sx={{ mt : 0.5, mb :1 }}>[png,jpeg,jpg,gif]</Typography> 
                    </Grid>

                    <Grid item md={12} sx={{width : '100%', textAlign : 'center'}}>
                        <Button type='submit' className='btn' variant="contained" >{t('envoyer')} </Button> 
                    </Grid>
                </Grid>    
            </form>
        </Paper>
   
    </Container>
  )

}