import React, {useState, useEffect} from 'react';
import {Typography, Tabs, Tab, styled, Grid,IconButton, Paper, Box, Menu, Badge, Button, CardActions, CircularProgress} from '@mui/material';
import PropTypes from 'prop-types'; 
import api from '../services/api';
import { Link } from 'react-router-dom';   
import { useTranslation } from 'react-i18next';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'; 
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Groups2Icon from '@mui/icons-material/Groups2'; 
import Carousel from 'react-material-ui-carousel'; 
import AddIcon from '@mui/icons-material/Add'; 
import MenuIcon from '@mui/icons-material/Menu'; 

import Freelance_resume from '../components/freelance_resume';
import Avis_resume from '../components/avis_resume';
import Freelance_chercer_par_domaine from '../components/freelance_cherche_par_domaine';





function TabPanel(props) {
    
    const {
        children,
        value,
        index,
        ...other
    } = props;


    return (
        <div role="tabpanel"
            hidden={
                value !== index
            }
            id={
                `simple-tabpanel-${index}`
            }
            aria-labelledby={
                `simple-tab-${index}`
            }
            {...other}>
            {
            value === index && (
                <Box sx={
                    {p: 3}
                }>
                    <Typography>{children}</Typography>
                </Box>
            )
        } </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

//Pour les GRID
const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const Accuiel = () => { 
    

    const { t, i18n } = useTranslation();
    const [valueTabComentMarche, setValueTabComentMarche] = useState(0);
    const [avis, setAvis] = useState([]);
    const [freelances, setFreelances] = useState([]);
    const [freelanceCount, setFreelanceCount] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const userData = JSON.parse(window.localStorage.getItem('userData')); 
    let code = 0;
    if(userData !=null ){ 
        let code = userData.code;
    }

    useEffect(() => {  
        handleGetAllAvis();
        document.title = t('title')
    },[]);
 
    const handleChangeComentMarcheLink = (event, newValue) => {
        setValueTabComentMarche(newValue);
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    

     

    const handleGetAllAvis = () => {   
        api.get('/api', {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {  
            setAvis(response.data.avis);
            setFreelances(response.data.freelances);
            setFreelanceCount(response.data.freelancesCountParDomaine); 
              
            setTimeout(()=>{
                setIsLoading(false)
            }, 0)
        }).catch(error => {           
            console.log(error);   
        });       
    }; 
     

    const listAvisSelonLangue = (avis.map((row) => {
        if(i18n.language === 'fr'){
            return({
                'id' : row.id,
                'titre' : row.titre_fr,
                'slug' : row.slug_fr,
                'etat' : row.etat,
                'description' : row.description_fr,
                'nomSociete' : row.societe.nom_fr,
                'periode' : row.periode,
                'budget_discute' : row.budget_discute,
                'budget_min' : row.budget_min,
                'budget_max' : row.budget_max,
                'codeCountry' : row.societe.codeCountry,
                'ouvert' : 'Ouvert',
                'execution' : 'En execution',
                'ferme' : 'Ferme',
                'Adiscute' : 'A discute',
                'budget' : 'Budget',
            })
        }
        else {
            return ({
                'id' : row.id,
                'titre' : row.titre_ar,
                'slug' : row.slug_ar,
                'etat' : row.etat,
                'codeCountry' : row.societe.codeCountry,
                'description' : row.description_ar,
                'nomSociete' : row.societe.nom_ar,
                'periode' : row.periode,
                'budget_discute' : row.budget_discute,
                'budget_min' : row.budget_min,
                'budget_max' : row.budget_max,
                'ouvert' : 'مفتوح',
                'execution' : 'قيد التنفيذ',
                'ferme' : 'مغلق',
                'Adiscute' : 'يناقش',
                'budget' : 'الميزانية',
            })
        } 
    }))

    var items = [
        {
            image: "./img/freelancer.jpeg",
            grand_titre: t('grand_titre'),
            petie_titre: t('petie_titre')
        },
        {
            image: "./img/contrat.jpeg",
            grand_titre: t('grand_titre2'),
            petie_titre: t('petie_titre3')

        }
    ]



    let chercher_freelances_par_domaine = (e) => {
        
        var domaine = e.target.value;
        api.get(`api/get_freelances_par_domaine/${domaine}`, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {  
            setFreelances(response.data.freelances);   
            handleCloseNavMenu(); 
            
        }).catch(error => {
            console.log(error)  
        });

    };


    
    return (
        <> 


        <Carousel align='center'  sx={{mr:3,ml:3, mt:2}}> 
            {items.map( (item, i) => 
                <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Item sx={{ background: '#e1ebea', height:305}}>
                            <Typography className='typoCarousel' sx={{fontSize:20, fontWeight:'900'}}>{item.grand_titre}</Typography>
                            <Typography className='typoCarousel' sx={{fontSize:17, fontWeight:'400', mt:3, fontSize:'15px'}}>{item.petie_titre}</Typography>
                            <Typography sx={{mt:3, textAlign:'center'}}> 
                            <Button component={Link} to={`/publier/${code}`} sx={{color:'black', fontWeight:'900', fontSize:17, textTransform:'initial', mt:4}}><AddIcon className='inline-icon-in-navbar'/> {t('publiez_votre_annonce')}</Button>
                            </Typography>
                    </Item>
                </Grid>
                <Grid item sm={6}>
                    <Item sx={{ background: '#e1ebea'}}>
                        <img src={item.image} width='100%' height='300' alt='profil'/>
                    </Item>
                </Grid>
            </Grid>
            )}
        </Carousel> 




            <Typography align='center' sx={{ fontWeight: '900',fontSize: 25, p: 2, color : 'black'}}><BusinessCenterIcon sx={{verticalAlign : 'sub', fontSize:30}}/>{t('com_c_marche')} </Typography> 
        
            <Box >  
                <Tabs value={valueTabComentMarche} 
                    onChange={handleChangeComentMarcheLink}
                    centered 
                    TabIndicatorProps={{ sx: { background: "#d72412", } }} >
                    
                    <Tab label={<span>{t('porteur_du_projet')}</span>}       sx={{width: '50%', fontWeight: '900',}}/>
                    <Tab label={t('freelanc')} sx={{width: '50%', fontWeight: '900',}}/>
                </Tabs> 

                <TabPanel value={valueTabComentMarche} index={0}> 
                    <Grid container rowSpacing={1} columnSpacing={{xs: 4, sm: 2, md: 3 }}>
                        <Grid item lg={4} md={12}  className="item" > 
                            <Item className='item' sx={{ borderRadius:5}}> 
                                <Badge  overlap="circular" className='badge'>
                                    <Typography className='typographyCmtMarche'>1</Typography>
                                </Badge>
                                <Typography variant="body1" align="center" className='typographyTitreCmtMarche'>{t('publiez_votre_annonce')}</Typography>
                                <Typography align='justify' className='desc_cmt_c_march_link'>{t('desc_addProject')} </Typography>  
                                <Button component={Link} to={`/publier/${code}`} sx={{color:'black', fontWeight:'900', fontSize:17, textTransform:'initial', color:'#d72412'}}><AddIcon className='inline-icon-in-navbar'/> {t('publiez_votre_annonce')}</Button>
                                   
                            </Item>
                        </Grid>
                        <Grid item lg={4} md={12} className="item" > 
                            <Item className='item' sx={{ borderRadius:5}}> 
                                <Badge overlap="circular" className='badge'>
                                    <Typography className='typographyCmtMarche' >2</Typography>
                                </Badge>
                                <Typography variant="body1" align="center" className='typographyTitreCmtMarche'> {t('desc_recevez_offres')} </Typography>
                                <Typography align='justify' className='desc_cmt_c_march_link' >{t('desc_offre')}</Typography> 
                            </Item>
                        </Grid>
                        <Grid item lg={4} md={12} className="item" sx={{}}> 
                            <Item className='item' sx={{ borderRadius:5}}> 
                                <Badge overlap="circular" className='badge' >
                                    <Typography className='typographyCmtMarche'>3</Typography>
                                </Badge>
                                <Typography variant="body1" align="center" className='typographyTitreCmtMarche'>{t('reception_du_projet')}</Typography>
                                <Typography align='justify' className='desc_cmt_c_march_link'>{t('desc_reception_du_projet')} </Typography> 
                            </Item>
                        </Grid>
                    </Grid>
                    
                </TabPanel>
                <TabPanel value={valueTabComentMarche} index={1}> 
                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                        <Grid item md={4} className="item"> 
                            <Item className='item' sx={{ borderRadius:5}}> 
                                <Badge overlap="circular" className='badge'>
                                    <Typography className='typographyCmtMarche'>1</Typography>
                                </Badge>
                                <Typography variant="body1" align="center" className='typographyTitreCmtMarche'>{t('Parcourir_les_services')}</Typography>
                                <Typography align='justify' className='typograDescService desc_cmt_c_march_link'>{t('descr_services')}</Typography>
                                <Button component={Link} to={`/publier/${code}`} sx={{color:'black', fontWeight:'900', fontSize:17, textTransform:'initial', mt:2, color:'#d72412'}}><AddIcon className='inline-icon-in-navbar'/> {t('publiez_votre_annonce')}</Button>
                            </Item>
                            
                        </Grid>
                        <Grid item md={4} className="item"> 
                            <Item className='item' sx={{ borderRadius:5}}> 
                                <Badge overlap="circular" className='badge'>
                                    <Typography className='typographyCmtMarche'>2</Typography>
                                </Badge>
                                <Typography variant="body1" align="center" className='typographyTitreCmtMarche'>{t('choix_offre')}</Typography>
                                <Typography align='justify' className='desc_cmt_c_march_link'> {t('desc_offre2')} </Typography>
                            </Item>

                            
                        </Grid>
                        <Grid item md={4} className="item">
                            <Item className='item' sx={{ borderRadius:5}}> 
                                <Badge overlap="circular" className='badge'>
                                    <Typography className='typographyCmtMarche'>3</Typography>
                                </Badge>
                                <Typography variant="body1" align="center" className='typographyTitreCmtMarche'>{t('livraison')}</Typography>
                                <Typography justify="flex-end" className='desc_cmt_c_march_link'>{t('desc_livraison')}</Typography> 
                            </Item>

                        </Grid>
                    </Grid> 
                </TabPanel>
            </Box>


            
         


            <Paper  align='center' sx={{p:0.5, ml:3,mr:3, background:'#18689f'}}>
                <Typography sx={{fontWeight: '900', fontSize: 20, color : '#fff'}}>{t('annonces')} </Typography>
            </Paper>

             

            <Box sx={{ pl: 3, pr: 3, mt: 1, }}> 
                {isLoading &&
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                } 
                {!isLoading && 
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}>
                    
                    {listAvisSelonLangue?.map((row) => ( 
                        (i18n.language == 'ar' && row.titre != null &&  (row.titre != 'null' && row.description != 'null' && row.nomSociete != 'null')) ?
                        <Grid item md={4} key={row.id}> 
                            <Avis_resume slug={row.slug} id={row.id} titre={row.titre.substring(0, 40)} etat={row.etat} ouvert={row.ouvert} execution={row.execution}
                                        ferme={row.ferme} description={row.description.substring(0, 200)} nomSociete={row.nomSociete} 
                                        budget_discute={row.budget_discute} Adiscute={row.Adiscute} budget_min={row.budget_min}
                                        budget_max={row.budget_max} periode={t(row.periode)} codeCountry={row.codeCountry}>

                            </Avis_resume>
                        </Grid> 
                        : (i18n.language == 'fr' && row.titre != null && (row.titre != 'null' && row.description != 'null' && row.nomSociete != 'null')) ?
                        <Grid item md={4} key={row.id}> 
                            <Avis_resume slug={row.slug} id={row.id} titre={row.titre.substring(0, 40)} etat={row.etat} ouvert={row.ouvert} execution={row.execution}
                                        ferme={row.ferme} description={row.description.substring(0, 200)} nomSociete={row.nomSociete} 
                                        budget_discute={row.budget_discute} Adiscute={row.Adiscute} budget_min={row.budget_min}
                                        budget_max={row.budget_max} periode={t(row.periode)} codeCountry={row.codeCountry}>

                            </Avis_resume>
                        </Grid> 
                        : null
                            
                        ))}
                </Grid> 
                }
                
            </Box> 



            <Paper  align='center' sx={{p: 0.5, mt:1,ml:3,mr:3, background:'#d72412'}}>
                <Typography sx={{fontWeight: '900', fontSize: 20, color : '#fff' }}>{t('freelancers')}</Typography>
            </Paper>
            


            <Box sx={{pl: 3, pr: 3, mt: 1}}>
                {isLoading &&
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                } 
                {!isLoading && 
                 
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}>
                    <Grid item md={3} sx={{ width :'100%'}}>
                        <Item elevation={0} sx={{background:'content-box', p:0}}>   
                          
                            <Box className='boxFreelanceInAcueil' sx={{display: {  xs: 'none', sm:'block' }}}> 
                                <Freelance_chercer_par_domaine chercher_freelances_par_domaine={chercher_freelances_par_domaine}
                                                               countFreelanceDeveloppementWeb={freelanceCount.Développement_web.length} countFreelanceDesign={freelanceCount.Design.length}
                                                               countFreelanceVentes={freelanceCount.Ventes.length} countFreelanceRedaction={freelanceCount.Redaction.length} 
                                                               freelanceServices_comptables={freelanceCount.Services_comptables.length} countFreelanceFormation={freelanceCount.Formation.length}
                                                               countFreelanceArchitecture={freelanceCount.Architecture.length} countFreelanceAutres={freelanceCount.Autres.length}
                                                               ></Freelance_chercer_par_domaine>
                            </Box>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                    display: { xs: 'block', md: 'none', margin : 7},
                                    }} >
                                    <Freelance_chercer_par_domaine chercher_freelances_par_domaine={chercher_freelances_par_domaine}
                                                               countFreelanceDeveloppementWeb={freelanceCount.Développement_web.length} countFreelanceDesign={freelanceCount.Design.length}
                                                               countFreelanceVentes={freelanceCount.Ventes.length} countFreelanceRedaction={freelanceCount.Redaction.length} 
                                                               freelanceServices_comptables={freelanceCount.Services_comptables.length} countFreelanceFormation={freelanceCount.Formation.length}
                                                               countFreelanceArchitecture={freelanceCount.Architecture.length} countFreelanceAutres={freelanceCount.Autres.length}
                                                               ></Freelance_chercer_par_domaine>
                                </Menu>

                            </Box>

                        </Item>
                    </Grid>

                    <Grid item md={9} sx={{ width :'100%'}}>
                        
                        <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}> 
                            {freelances?.map((row) => (
                            <Grid item key={row.id} md={4} sx={{ width :'100%'}}>  
                                <Freelance_resume photo={row.photo} noteProfile={row.note_profile} nom={row.nom} domaine={row.domaine} commentaire_count={row.commentaire_count} view_count={row.view_count} commentaire_avg_etoile={row.commentaire_avg_etoile} id={row.id} codeCountry={row.codeCountry}></Freelance_resume>
                            </Grid>
                            ))} 
                            <Grid item md={12} sx={{ width :'100%'}}> 
                                <Button className='plus-freelances' component={Link} to="/freelances"><Groups2Icon sx={{p:1}} className='inline-icon-in-navbar'/> {t('puls_freelance')} </Button>
                            </Grid>
                        </Grid> 
                    </Grid>

                </Grid>
                } 

            </Box> 


            

            


            <Box sx={{pl: 3, pr: 3, mt: 2}}>
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item sm={12} xs={12} sx={{ width :'100%'}}>
                        <Item elevation={0} sx={{background : '#e1ebea !important', minHeight : 98}}>
                           <Typography sx={{fontWeight : '600', fontSize : 20, color:'black'}}>
                              {t('email')}
                           </Typography>
                           <Typography className='contact' sx={{fontWeight : '400', fontSize : 18, color:'black'}}>
                             <EmailIcon sx={{verticalAlign:'sub'}}/> contact@link.mr
                           </Typography>
                        </Item>
                    </Grid>

                    {/* <Grid item  sm={6} xs={6} sx={{ width :'50%'}}>
                        <Item elevation={0} sx={{background : '#e1ebea !important', minHeight : 98}}>
                           <Typography sx={{fontWeight : '600', fontSize : 20, color:'black'}}>
                             {t('phone')}
                           </Typography>
                            <Typography className='contact' sx={{fontWeight : '400', fontSize : 18, color:'black'}}>
                                 <PhoneIphoneIcon sx={{verticalAlign:'sub'}}/> 38 56 56 56
                            </Typography>
                              
                        </Item>
                    </Grid>  */}
                    
                </Grid>
            </Box>           
        </>

    );

}

export default Accuiel;
