import React, {useState} from 'react'; 
import Container from '@mui/material/Container'; 
import { Grid, Typography, Paper, Badge, CardContent, CardActionArea, styled } from '@mui/material';  
import { useTranslation } from 'react-i18next';  
import { Link } from 'react-router-dom';  
import BusinessIcon from '@mui/icons-material/Business'; 
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; 

//Pour les GRID
const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Avis_resume = (props) => {
    const { t, i18n } = useTranslation(); 
    // const [valueRatings, setValueRatings] = useState(0);
   

    return ( 
        <CardActionArea component={Link} to={`/avis/${props.slug}/${props.id}`} > 
            <Item elevation={0} className='crd crd-accueil'>
                <CardContent sx={{textAlign: 'left'}}> 

                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}>
                        <Grid item md={10}>
                            <Typography className='typoTitreAvis' >
                                {props.titre}
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography > 
                            {props.etat == 0 ? <Badge className='badgeOuvet' color="success" >{props.ouvert}</Badge> 
                                : props.etat == 50 ? <Badge className='badgeExecution' color="primary">{props.execution}</Badge> 
                                : <Badge color="error" className='badgeFermer'>{props.ferme}</Badge>}
                            </Typography>
                        </Grid>
                    </Grid> 
                    
                    <Typography sx={{mb: 3,color:'black', fontSize:11}} color="text.secondary" align='justify'>
                        {props.description}
                    </Typography>

                    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}>
                        <Grid item md={4} >
                            <Typography className='typoNomSociete' style={{display:'flex'}}>
                                <BusinessIcon className='inline-icon'/> 

                                {props.codeCountry != null ? 
                                    <div className=' react-tel-input' style={{width:'auto'}}>
                                        <div className={ `flag ` + props.codeCountry}>
                                        </div>
                                    </div>  
                                : null}
                                
                                <span style={{padding:'0px 2px 0px 2px'}}>{props.nomSociete}</span>

                            </Typography>           
                        </Grid>
                        <Grid item md={8} >  
                            {props.budget_discute == 1? <Typography className='typoDiscute'><AttachMoneyIcon className='inline-icon'/> {props.Adiscute} </Typography>: <Typography className='typoDiscute'><AttachMoneyIcon className='inline-icon'/> : [{t('min')} : {props.budget_min} - {t('max')} : {props.budget_max}]</Typography> }                                                                                      
                        </Grid>
                        <Grid item md={12} >
                            <Typography className='peride' sx={{color:'#d72412', fontSize:14}}>
                                <QueryBuilderIcon className='inline-icon'/> {props.periode}
                            </Typography>
                        </Grid>
                    </Grid>

                </CardContent>

            </Item> 
        </CardActionArea> 
    );
}

export default Avis_resume;
