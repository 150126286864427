import React, {useState, useEffect} from 'react'; 
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'; 
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';  
import Button from '@mui/material/Button'; 
import {styled} from '@mui/material/styles';
import GroupIcon from '@mui/icons-material/Group';
import api from '../services/api';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';


const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Freelances_bo = () => {
    const { t, i18n } = useTranslation();
    const [freelance, setfreelance] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {  
        handleGetFreelances();
    }, []);
 
    const handleGetFreelances = () => {   
        api.get('/api/list_freelance', {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {  
            setfreelance(response.data.freelance); 
             setTimeout(()=>{
        setIsLoading(false)
      }, 2000)
        }).catch(error => {
            console.log(error); 
        });
         
    }; 
    
     

    return (
    <>
          <Box sx={
                {
                    pl: 3,
                    pr: 3,
                    pt:1
                }
            }>
                <Paper  
                    sx={
                        {p: 2}
                }>
                    <Typography sx={{bgcolor: '#fff', mt: 1, fontWeight: '900', fontSize: 20, }}> <GroupIcon/> Les freelance </Typography>
                </Paper>
            </Box> 

            {isLoading &&
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress sx={{mt : 16}}/>
                </Box>
                } 
                {!isLoading && 

            <Box sx={
                {
                    pl: 3,
                    pr: 3,
                    mt: 2,
                }
            }>
                <Grid container
                    rowSpacing={1}
                    columnSpacing={
                        {
                            xs: 1,
                            sm: 2,
                            md: 3,
                        }
                }>
                     {freelance?.map((row) => (
                    <Grid item md={3} sx={{ width :'100%'}}>
                        <Item elevation={0} >
                            <React.Fragment>
                                <CardContent sx={ {textAlign: 'left'} }>
                                    <Typography component="div"  sx={{mb: 2, textAlign : 'center'}}>
                                       {row.photo == null ? <img  src='img/emptyprofile.png' height='140' width='140' alt='profil'/> : 
                                        <img src={'https://link.mr/back/storage/images/' + row.photo} alt='Profile' width="140" height="140" sx={{ width: 56, height: 56, borderRadius: '50%'}} />
                                        }
                                    </Typography>
                                    <Typography  sx={{ mt: 1,fontWeight : '900', textAlign : 'center'}}>
                                             {row.nom}  
                                        </Typography>
                                        <Typography  sx={{ mt: 1, textAlign : 'center', color : 'red'}}> 
                                             {row.domaine} 
                                        </Typography>
                                    <CardActions sx={{width : '100%'}}>
                                     <Typography sx={{width : '100%'}}>  
                                     
                                    <Typography  sx={{ textAlign : 'center', mt :1}}> 
                                        <Button component={Link} to={`freelance/${row.id}`} variant="contained">Profil</Button>
                                    </Typography>
                                    </Typography> 
                                    </CardActions>
                                </CardContent>
                            </React.Fragment>
                        </Item>
                    </Grid>
                      ))}  
                </Grid>
            </Box>
            }
    </>
  );
};

export default Freelances_bo;