import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import api from '../services/api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'; 
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';



export default function Avis_list_bo() {
    const [avis, setavis] = useState([]); 
    const { t, i18n } = useTranslation();


     useEffect(() => {  
        handleGetavis();
    }, []);


    const handleGetavis = () => {   
        api.get('/api/list_avis', {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {   
           setavis(response.data.job)
            
        }).catch(error => {
            
            console.log(error); 
        });
         
    }; 

 

  return (
    <Container sx={{mt :3}}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, background : '#eceef0', mt : 1 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="right">Titre fr</TableCell>
                    <TableCell align="right">Titre Ar</TableCell> 
                    <TableCell align="right">Action</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {avis?.map((row) => (
                    <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell scope="row"  component={Link} to={`/avis/${row.slug_fr}/${row.id}`}>
                        {row.id}
                    </TableCell>
                    <TableCell align="right">{row.titre_fr}</TableCell>
                    <TableCell align="right">{row.titre_ar}</TableCell> 
                    <TableCell align="right">
                         <Button variant="contained" component={Link} to={`/modifierAvis/${row.id}`}>Edite</Button>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
      
    </Container>
  );
}
