import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';  
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import api from '../services/api'; 
import {useNavigate} from 'react-router-dom'; 
import Swal from 'sweetalert2';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import InputLabel from '@mui/material/InputLabel';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Freelance_service_edit() { 
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [service, setService] = useState({});
    const [serviceId, setServiceId] = useState(); 
    const [freelanceId, setFreelanceId] = useState();
    const [titre, setTitre] = useState('')
    const [prix, setPrix] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const ud = JSON.parse(window.localStorage.getItem('userData'));
    const {id} = useParams();

    const changeHandler = (event) => {
        setImage(event.target.files[0]);
    };

    useEffect(() => {  
        handleGetService();
        i18n.language == 'fr' ? document.title = 'Modifier service - ' + t('title')  : document.title = 'تعديل خدمة - '  + t('title') 
    },[]);

  
    const handleGetService = () => {  
       if(ud != null){ 
            const servicef = ud.services; 
            const service = servicef.find(obj => { 
                return obj.id == parseInt(id)
            }) 
            console.log('ud ss')  
            console.log(ud)  
            if(service != null){
                console.log('in ser')
                setService(service)    
                setServiceId(service.id)    
                setFreelanceId(service.freelance_id)    
                setTitre(service.titre)       
                setDescription(service.description)  
                setPrix(service.prix)  
            }else{
                console.log('no ser')
                navigate('/profil')
            }
        }else{
            navigate('/')
        }
    };   

    const handlemodifierService = (e) => {
        e.preventDefault();
        Swal.fire ({
            title: t('attender'),
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading()
            }
        }) 
        api.get('/sanctum/csrf-cookie').then((response) => {
            if (ud != null) {  
                const form = new FormData(); 
                form.append('id', serviceId);
                form.append('freelanceId', freelanceId);
                form.append('titre', titre);
                form.append('prix', prix);
                form.append('description', description);
                form.append('image', image);

                api.post( `/api/modifierService`, form).then(response => { 
                    console.log('in edite service')
                    console.log(response.data)
                    const service = response.data.services;
                    const servicef = ud.services.map(obj => {
                            if (obj.id == service.id) {
                                return service;
                            }
                            return obj;
                        });
                        
                        ud.services=servicef;  
                        window.localStorage.setItem('userData', JSON.stringify(ud)); 

                    i18n.language == 'fr' ? Swal.fire({icon: "success", text: response.data.messagefr,confirmButtonText: t('ok')}) :  Swal.fire({icon: "success", text: response.data.messageAr,confirmButtonText: t('ok')})
                   navigate(`/profil`)
                }).catch(error => {
                console.log(error);
                });
            }else{
                navigate('/');
            } 
        }); 
    };

     
    

  return (
    <Container>
         
        <Paper sx={{p: 2, mt: 3 }} elevation={0}>
            <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 1,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#1976d2'}}>
                {t('modifierService')}
            </Typography>                         
        </Paper> 

         
        <Paper sx={{p: 4, mt: 2}} elevation={0}>
             <form onSubmit={handlemodifierService} >
                <Grid container spacing={2} >
                    <Grid item md={12} sx={{width : '100%'}}>
                            <input type="hidden" name='freelanceId' value={freelanceId}
                            onChange={(event) => {setFreelanceId(event.target.value)}} />
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                        <TextField id="outlined-uncontrolled" 
                            required
                            label={t('titreP')}
                            variant="outlined"
                            sx={{width : '100%'}} 
                            value={titre}
                            onChange={(event) => {setTitre(event.target.value)}}
                            />
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                        <TextField id="outlined-uncontrolled"
                            required 
                            label={t('prix')}
                            variant="outlined"
                            sx={{width : '100%'}} 
                            value={prix}
                            onChange={(event) => {setPrix(event.target.value)}}
                            />
                    </Grid>

                    <Grid item md={12} sx={{width : '100%'}}>
                        <TextareaAutosize
                            required
                            maxRows={40}
                            aria-label="maximum height"
                            placeholder={t('message_titre_desciption')} 
                            style={{ width: '100%', height : 100 }} 
                            value={description}
                            onChange={(event) => {setDescription(event.target.value)}} 
                            />  
                    </Grid>

                    <Grid item md={12} sx={{width : '100%'}}>
                            <Typography variant="body2" sx={{ mt : 0.5, mb :1, fontWeight : 'bold',  }}>{t('image')}</Typography>
                            { service.photo == null ? '' : 
                            <img src={'https://link.mr/back/storage/images/' + service.photo} alt='Not found' width="auto" height="auto" />
                            }
                        <br/>
                        <br/>
                        <input type="file" className='control-form' onChange={changeHandler}/> 
                        
                            <Typography variant="body2" sx={{ mt : 0.5, mb :1 }}>[png,jpeg,jpg,gif]</Typography> 
                    </Grid>

                    <Grid item md={12} sx={{width : '100%', textAlign : 'center'}}>
                        <Button className='btn' variant="contained" type='submit' >{t('envoyer')} </Button>
                        
                    </Grid>
                </Grid> 
            </form>                        
        </Paper>
       
    </Container>
  )

}