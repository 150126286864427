import React, {useState, useEffect} from 'react';   
import { Grid, Typography, Paper } from '@mui/material';  
import { useTranslation } from 'react-i18next';  
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';  
import Container from '@mui/material/Container';  
import api from '../services/api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'

function TabPanel(props) {
    
    const {
        children,
        value,
        index,
        ...other
    } = props;


    return (
        <div role="tabpanel"
            hidden={
                value !== index
            }
            id={
                `simple-tabpanel-${index}`
            }
            aria-labelledby={
                `simple-tab-${index}`
            }
            {...other}>
            {
            value == index && (
                <Box sx={
                    {p: 3}
                }>
                    <Typography>{children}</Typography>
                </Box>
            )
        } </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



const SociteProfile = () => {
    const { t, i18n } = useTranslation();
    const [valueTabComentMarche, setValueTabComentMarche] = useState(0); 
    const [nom, setNom] = useState(''); 
    const [email, setEmail] = useState(''); 
    const [telephone, setTelephone] = useState(''); 
    const [codeCoutrey, setCodeCoutrey] = useState(''); 
    const [avis, setAvis] = useState([]); 
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const id = userData.socite.id
    useEffect(() => { 
      console.log(userData.socite)
      console.log('userData in sos')
      GetAvis();
    },[]);

    const handleChangeComentMarcheLink = (event, newValue) => {
        setValueTabComentMarche(newValue);
    }; 
    
    const GetAvis = () => {   
        api.get(`/api/getTousAvisPourUneSociete/${id}`, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {    
            console.log(response.data)
            setAvis(response.data.avis) 
            if(i18n.language == 'fr'){ 
                setNom(userData.socite.nom_fr)
            }
            else { 
                setNom(userData.socite.nom_ar)
            }
            setEmail(userData.socite.email)
            setCodeCoutrey(userData.socite.codeCountry)
            setTelephone(userData.socite.telephone)
        }).catch(error => {
            console.log(error); 
        });
         
    }; 
   

    return ( 
        <Container sx={{mt : 3}}>
            

            {/* <Box sx={{m:'10px 5px 0px 5px'}}>  
                <Tabs value={valueTabComentMarche} 
                    onChange={handleChangeComentMarcheLink}
                    centered 
                    TabIndicatorProps={{ sx: { background: "#d72412", } }} >
                    
                    <Tab label={<span>{t('info_Soc')}</span>}       sx={{width: '50%', fontWeight: '900',}}/>
                    <Tab label={t('avis')} sx={{width: '50%', fontWeight: '900',}}/>
                </Tabs> 

                <TabPanel value={valueTabComentMarche} index={0}> 
                    <Grid container rowSpacing={1} columnSpacing={{xs: 4, sm: 2, md: 3 }}>

                        <Grid item md={4} className="item" >   
                                <span>{t('nom')} :</span> {nom}
                        </Grid>

                        <Grid item md={4} className="item" >   
                                <span>{t('email')} :</span>  {email}
                        </Grid>

                        <Grid item md={4} className="item" >  
                                <span>{t('telephone')} :</span> {telephone}
                        </Grid>

                        <Grid item md={4} className="item" >  
                                <span>{t('email')} :</span> 
                        </Grid>

                    </Grid>    
                    
                </TabPanel>

                <TabPanel value={valueTabComentMarche} index={1}> 
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650, background : '#eceef0', mt : 1 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="right">Titre fr</TableCell>
                                <TableCell align="right">Titre Ar</TableCell> 
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                              {avis.map((row) => (
                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell scope="row" component={Link} to={`/avis/${row.slug_fr}/${row.id}`}>
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="right" component={Link} to={`/avis/${row.slug_fr}/${row.id}`}>{row.titre_fr}</TableCell>
                                    <TableCell align="right" component={Link} to={`/avis/${row.slug_fr}/${row.id}`}>{row.titre_ar}</TableCell> 
                                    <TableCell align="right">
                                    <Button variant="contained" component={Link} to={`/modifierAvis/${row.id}`}>Edite</Button>
                                    </TableCell>
                                </TableRow>
                             ))}
                            </TableBody>
                        </Table>
                        </TableContainer> 
                </TabPanel>
            </Box> */}




            <Grid container spacing={2} >
                <Grid item sm={4} sx={{width:'100%'}}>
                    <Paper className='paperProfile' sx={{p:2,textAlign :'start' }} elevation={0}> 
                         
                        <Typography sx={{mt : 1, fontWeight : '900'}}> <span>{t('nom')} :</span> {nom}</Typography>
                        <Typography sx={{mt : 1, fontWeight : '900'}}> <span>{t('email')} :</span> {email}</Typography>
                        <Typography sx={{mt : 1, fontWeight : '900'}}> <span>{t('telephone')} :</span> {telephone}</Typography>
                         
                        <div className=' react-tel-input' style={{direction:'inherit'}}>
                            <div className={ `flag ` + codeCoutrey} style={{margin:'auto', textAlign:'center'}}>
                            </div>
                        </div>  
 
                    </Paper>

                </Grid>

                <Grid item sm={8}>
                    <Paper className='paperProfile' sx={{p:2,textAlign :'center' }} elevation={0}>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650, background : '#eceef0', mt : 1 }} aria-label="simple table">
                                <TableHead>
                                <TableRow> 
                                    <TableCell align="right">Titre</TableCell>
                                    <TableCell align="right">العنوان</TableCell> 
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {avis.map((row) => (
                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                         
                                        <TableCell align="right" component={Link} to={`/avis/${row.slug_fr}/${row.id}`}>{row.titre_fr}</TableCell>
                                        <TableCell align="right" component={Link} to={`/avis/${row.slug_fr}/${row.id}`}>{row.titre_ar}</TableCell> 
                                        <TableCell align="right">
                                        <Button variant="contained" component={Link} to={`/modifierAvis/${row.id}`}>{t('edite')}</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                
                        
                </Grid>

            
        </Grid>
            
        </Container>
    );
}

export default SociteProfile;
