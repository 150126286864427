import React, {useState} from 'react';   
import { Grid, Typography, Paper } from '@mui/material';  
import { useTranslation } from 'react-i18next';   
import {Divider} from '@mui/material'; 
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';   
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';  
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import Freelance_projet from './freelance_projet';
import Freelance_commentaire from './freelance_commentaire';
import Freelance_form_commentaire from '../components/freelance_form_commentaire';
import { Pagination } from '@mui/material/';
import Stack from '@mui/material/Stack'; 
import FreelanceService from './freelance_service';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";



function TabPanel(props) {
    
    const {
        children,
        value,
        index,
        ...other
    } = props;


    return (
        <div role="tabpanel"
            hidden={
                value !== index
            }
            id={
                `simple-tabpanel-${index}`
            }
            aria-labelledby={
                `simple-tab-${index}`
            }
            {...other}>
            {
            value == index && (
                <Box sx={
                    {p: 3}
                }>
                    <Typography>{children}</Typography>
                </Box>
            )
        } </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Freelance_detail = (props) => {
    const { t, i18n } = useTranslation();
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const [valueTabComentMarche, setValueTabComentMarche] = useState(0); 

    const handleChangeComentMarcheLink = (event, newValue) => {
        setValueTabComentMarche(newValue);
    };
    
    return ( 
        
        <>
            <Grid item sm={3} sx={{width:'100%'}}>
                <Paper className='paperProfile' sx={{p:2,textAlign :'center' }} elevation={0}>
                    {/* <div style={{position: 'absolute', width: '100px', height: '100px'}}>
                        <div className='progress-profil progress-profil-mobil'>
                            <CircularProgressbar value={props.noteProfile} text={null} />
                        </div>
                    </div>


                    {props.photo == null ? <img className='image' src='../img/emptyprofile.png' alt='profil'/> : 
                    <img className='image' src={'https://link.mr/back/storage/images/' + props.photo} alt='Profile'/>
                    } */}

                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgressbar className='progress-profil' value={props.noteProfile}  />    
                        <Typography position='absolute' component="div" sx={{ textAlign : 'center'}}>
                            {props.photo == null ? <img className='image' src='/img/emptyprofile.png'  alt='profil'/> : 
                            <img className='image' src={'https://link.mr/back/storage/images/' + props.photo}  />
                            }
                        </Typography>
                    </Box>
                    <br />
                    <Typography sx={{mt : 1, fontWeight : '900'}}>{props.nom}</Typography>
                    <Typography sx={{mt : 1, color : '#d72412'}}>{t(props.domaine)}</Typography>
                    {props.codeCountry != null ?
                    <div className=' react-tel-input' style={{direction:'inherit', textAlign:'-moz-center'}}>
                        <div className={ `flag ` + props.codeCountry} style={{margin:'auto', textAlign:'center'}}>
                        </div>
                    </div>
                    : null}
                    <Typography sx={{mt : 1}}><CalendarMonthIcon sx={{color:'#d72412'}} className='inline-icon-in-navbar'/> {t('dateIns')} : {Moment(props.created_at).format('DD/MM/YYYY')}</Typography>
                </Paper>  

            </Grid>

            <Grid item sm={9}>
                     
                <Paper elevation={0}>  
                    <Typography sx={{color : '#d72412', fontWeight :'bold', p:2, width:'95%'}}>{t('Aprops_moi')}
                    
                    { (userData != null && userData.signedIn && userData.user.email == props.email) || (userData != null && userData.user.is_admin == 1) ?
                    <Button component={Link} to={`/editeProfile/${props.id}`} sx={{height:20, width:200, fontWeight:'900', fontSize:16, float:'inline-end', pt:1}}><ModeEditOutlineIcon className='inline-icon-in-navbar'/> {t('mod_profile')}</Button>
                    :''
                    }

                    </Typography>

                    
                    <Divider sx={{ mt: 1,}}/>
                    {props.description == null || props.description == 'null' ? <Typography>{t('message_apropos_freelance')}</Typography> : <Typography sx={{mt:1, width:'95%', p:2, justifyContent:'center'}}>{props.description}</Typography>} 
                        
                </Paper>
                     

                 
                <Paper sx={{ mt:1}} elevation={0}>
                    {/* tab pour pc */}
                    <Tabs value={valueTabComentMarche} 
                        onChange={handleChangeComentMarcheLink}
                         
                        TabIndicatorProps={{ sx: { background: "#d72412" } }} 
                        sx={{display: { xs: 'none', md: 'flex' }}}
                        >
                        <Tab label={<span>{t('information')}</span>}       sx={{width: '25%', fontWeight: '900',}}/>
                        <Tab label={t('mes_realisation')} sx={{width: '25%', fontWeight: '900',}}/>
                        <Tab label={t('mes_offres')} sx={{width: '25%', fontWeight: '900',}}/>
                        <Tab label={t('commentaires')} sx={{width: '25%', fontWeight: '900',}}/> 
                    </Tabs> 

                    {/* tab pour mobile */}
                    <Tabs value={valueTabComentMarche} 
                        onChange={handleChangeComentMarcheLink} 
                        TabIndicatorProps={{ sx: { background: "#d72412" } }} 
                        sx={{display: { lg: 'none', md: 'grid' }}}
                        > 
                        <Tab label={<span>{t('information_mobile')}</span>}  sx={{ fontWeight: '900'}}/>
                        <Tab label={t('mes_realisation_mobile')} sx={{ fontWeight: '900',}}/>
                        <Tab label={t('mes_offres')} sx={{ fontWeight: '900',}}/>
                        <Tab label={t('commentaires_mobile')} sx={{ fontWeight: '900',}}/> 
                    </Tabs>

                    <TabPanel value={valueTabComentMarche} index={0}>
                        <Grid container spacing={2} >
                            <Grid item sm={6}>
                                <Paper sx={{p:2}} elevation={0}>
                                    <Typography sx={{ fontWeight :'bold', mb : 2}}>{t('email')}</Typography>
                                    <Typography>{props.email}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item sm={6}>
                                <Paper sx={{p:2}} elevation={0}>
                                    <Typography sx={{fontWeight :'bold', mb : 2}}>{t('phone')}</Typography>
                                    <Typography>{props.telephone}</Typography> 
                                </Paper>
                            </Grid>
                              
                            <Grid item sm={12} sx={{p:2}}> 
                                <FacebookIcon sx={{p:1, color:'#3b5998'}} onClick={event => (props.facebook != null) &&  props.facebook.startsWith("https://www.facebook.com") ? window.open(props.facebook, '_blank') : Swal.fire({icon: "error", text: t('erreur_fb'),}) }/>
                                <TwitterIcon sx={{p:1, color:'#1D9BF0'}} onClick={event => (props.twitter != null) &&  props.twitter.startsWith("https://www.twitter.com/") ? window.open(props.twitter, '_blank') : Swal.fire({icon: "error", text: t('erreur_twitt'),}) }/>
                                <YouTubeIcon sx={{p:1, color:'#FF0000'}} onClick={event =>  (props.youtube != null) &&  props.youtube.startsWith("https://www.youtube.com/") ?  window.open(props.youtube, '_blank') : Swal.fire({icon: "error", text: t('erreur_youtube'),}) }/> 
                            </Grid> 
                            
                        </Grid> 
                    </TabPanel>

                    <TabPanel value={valueTabComentMarche} index={1}> 
                        <Grid container spacing={2}> 
                            {/* {(userData != null  && userData.signedIn) || (userData != null  && userData.signedIn && userData.user.is_admin == 1 ) ?
                            <Grid item sm={12}>  
                                <Button component={Link} to="/ajouterProject" sx={{float:'inline-end', fontWeight:'900', fontSize:16}}><AddIcon className='inline-icon-in-navbar'/>{t('addProject')}</Button>
                            </Grid>
                            :''}   */}

                            {props.portofolio.length == 0 ? <Typography>{t('empty_projets')}</Typography> :
                            <>
                                {props.portofolio?.map((row) => (
                                    <Grid item sm={4}>
                                        <Freelance_projet id={row.id} photo={row.photo} titre={row.titre}></Freelance_projet>
                                    </Grid>
                                ))} 
                            </>
                            }  
                            
                            
                        </Grid>
                    </TabPanel>

                    <TabPanel value={valueTabComentMarche} index={2}> 
                        <Grid container spacing={2}> 
                            {/* {(userData != null  && userData.signedIn) || (userData != null  && userData.signedIn && userData.user.is_admin == 1 ) ?
                            <Grid item sm={12}>  
                                <Button component={Link} to="/ajouterService" sx={{float:'inline-end', fontWeight:'900', fontSize:16}}><AddIcon className='inline-icon-in-navbar'/>{t('addService')}</Button>
                            </Grid>
                            :''}  */}
                           
                           {props.offres_freelance.length == 0 ? <Typography>{t('empty_service')}</Typography> :
                            <>
                                {props.offres_freelance?.map((row) => (
                                    <Grid item sm={4}>
                                        <FreelanceService id={row.id} photo={row.photo} titre={row.titre}></FreelanceService>
                                    </Grid>
                                ))} 
                            </>
                            }  
                        </Grid>
                        
                    </TabPanel>

                    <TabPanel value={valueTabComentMarche} index={3}>  
                        {props.commentaires.map((row) => (
                        <>
                            <Freelance_commentaire nom={row.nom} email={row.email} etoile={row.etoile} commentaire={row.commentaire}
                                                   valider_commentaire={row.valider_commentaire} idCommentaire={row.id}
                                                   ></Freelance_commentaire>
                            <Divider sx={{ m: 1}}/> 

                        </> 
                        ))}
                        <Stack alignItems="center" sx={{p:3}}>
                            <Pagination hideNextButton={true} hidePrevButton={true} sx={{ background:'#fff'}} count={props.pagesNumber} page={props.pageNumber} onChange={props.paginate} variant="outlined" color="primary" shape="rounded" />
                        </Stack>
                        
                        <Freelance_form_commentaire freelanceId={props.freelanceId} idurl={props.idurl} idfreelance={props.idfreelance}></Freelance_form_commentaire>
                        
                    </TabPanel>
                </Paper>
                     
            </Grid>

            
        </>
    );
}

export default Freelance_detail;
