import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';  
import Paper from '@mui/material/Paper'; 
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import api from '../services/api';  
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'; 
import {useNavigate} from 'react-router-dom';


const ResetPassword = () => {
    const { t, i18n } = useTranslation();  
    const [email, setEmail] = useState(''); 
    const navigate = useNavigate();  


    useEffect(() => {  
        document.title = t('reset_password') + ' - ' + t('title') 
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault(); 
        Swal.fire ({ 
          title : t('attender'),
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading ()
          }
        })
        api.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('email', email);
            
            api.post(`api/sendPasswordResetLinkEmail`, formData, {
                headers: {
                    'Accept': 'application/json'
                }
            }).then(({data}) => { 
                console.log(data)
                // setMessage(data.message)
                Swal.fire({icon: "success", text: t('msg_reset_password'), confirmButtonText: t('ok'),})
                // navigate(`/`)
                
            }).catch(({response}) => {
                
            }) 
        });


    }

    return (


    <Container>  
        <Paper sx={{p: 7, mt:3}}>  
            <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 3,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#18689f'}}>
                {t('reset_password')}
            </Typography>  
            
            

            <Grid container spacing={2} >
            <Grid item md={12} sx={{width : '100%'}}>
                <TextField id="outlined-uncontrolled" 
                label={t('email')}
                variant="outlined"
                sx={{width : '100%', direction:'ltr'}} 
                value={email}
                onChange={(event) => {setEmail(event.target.value)}} 
                />
             </Grid>

            <Grid item md={12} sx={{width : '100%',  textAlign : 'center'}}> 
                <Button  variant="contained" className='btn'  onClick={handleSubmit}>{t('envoyer')} </Button>
            </Grid> 

            </Grid>
        </Paper>  
   </Container>
    );
}

export default ResetPassword;
