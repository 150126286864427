import React, {useEffect, useState} from 'react';
import api from '../services/api'; 
import Container from '@mui/material/Container';
import { Grid, Typography, Paper, Box } from '@mui/material'; 
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Moment from 'moment';
import { CardActionArea } from '@mui/material'; 
import { Link } from 'react-router-dom'; 
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Swal from 'sweetalert2';
import {Divider} from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import Freelance_detail from '../components/freelance_detail';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Freelance_projet from '../components/freelance_projet';
import Freelance_commentaire from '../components/freelance_commentaire';
import FreelanceService from '../components/freelance_service';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function TabPanel(props) {
    
    const {
        children,
        value,
        index,
        ...other
    } = props;


    return (
        <div role="tabpanel"
            hidden={
                value !== index
            }
            id={
                `simple-tabpanel-${index}`
            }
            aria-labelledby={
                `simple-tab-${index}`
            }
            {...other}>
            {
            value == index && (
                <Box sx={
                    {p: 3}
                }>
                    <Typography>{children}</Typography>
                </Box>
            )
        } </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



export default function Freelance_mon_profile() {  
    const { t, i18n } = useTranslation();
    const [id, setId] = useState();
    const [freelance, setFreelance] = useState({});
    const [portofolio, setPortofolio] = useState([]);
    const [offresFreelance, setOffresFreelance] = useState([]);
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [youtube, setYoutube] = useState('');
    const [codeCountry, setCodeCountry] = useState('');
    const [commentaires, setCommentaires] = useState([]);
    const [freelanceId, setfreelanceId] = useState(0);

    const navigate = useNavigate();
    const userData = JSON.parse(window.localStorage.getItem('userData')); 
    const [valueTabComentMarche, setValueTabComentMarche] = useState(0);
   
    useEffect(() => {  
        if (userData != null && userData.user.is_admin == 1 && userData.signedIn) {
            navigate('/rim');
        }
        else if(userData != null && userData.user.type == 0 && userData.signedIn){
          navigate('/profil');
        }
        else if(userData != null && userData.user.type == 1 && userData.signedIn){
          navigate(`/publier/${userData.code}`);
        }
        else{
            navigate('/');
        }
        handleGetFreelancere();
        i18n.language == 'fr' ? document.title = 'Profile - ' + t('title')  : document.title = 'حسابي -' + t('title') 
    },[]);


    const handleChangeComentMarcheLink = (event, newValue) => {
        setValueTabComentMarche(newValue);
    };

     const handleGetFreelancere = () => { 
        if (userData != null) {
        const email = userData.user.email;
        api.get(`/api/profile/${email}`, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {   
            setFreelance(response.data.freelance)
             setFacebook(response.data.freelance.facebook)
             setPortofolio(response.data.portofolio)
             setOffresFreelance(response.data.offres_freelance)
             setCodeCountry(response.data.freelance.codeCountry)
            setTwitter(response.data.freelance.twitter)
            setYoutube(response.data.freelance.insta) 
            setCommentaires(response.data.commentaires)
             setfreelanceId(response.data.freelance.id)
            
        }).catch(error => {
            console.log(error); 
        });
        }   
    };  


    
  

  return ( 
    
    <Container sx={{mt : 3}}>
        <Grid container spacing={2} >
            <Grid item sm={3} sx={{width:'100%'}}>
                <Paper className='paperProfile' sx={{p:2,textAlign :'center' }} elevation={0}>
                    

                    {/* <div style={{position: 'absolute', width: '100px', height: '100px'}}>
                        <div className='progress-profil progress-profil-mobil'>
                            <CircularProgressbar value={freelance.note_profile} textForPercentage={null} />
                        </div>
                    </div>
                    
                    {freelance.photo == null ? <img className='image' src='../img/emptyprofile.png' height='140' width='140' alt='profil'/> : 
                    <img className='image' src={'https://link.mr/back/storage/images/' + freelance.photo} alt='Profile' width="140" height="140" sx={{ width: 56, height: 56, borderRadius: '50%'}} />
                    } */}
                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgressbar className='progress-profil' value={freelance.noteProfile}  />    
                        <Typography position='absolute' component="div" sx={{ textAlign : 'center'}}>
                            {freelance.photo == null ? <img className='image' src='/img/emptyprofile.png'  alt='profil'/> : 
                            <img className='image' src={'https://link.mr/back/storage/images/' + freelance.photo}  />
                            }
                        </Typography>
                    </Box>
                    <br />

                    <Typography sx={{mt : 1, fontWeight : '900'}}>{freelance.nom}</Typography>
                    <Typography sx={{mt : 1, color : '#d72412'}}>{t(freelance.domaine)}</Typography>
                    {freelance.codeCountry != null ?
                    <div className=' react-tel-input' style={{direction:'inherit', textAlign:'-moz-center'}}>
                        <div className={ `flag ` + freelance.codeCountry} style={{margin:'auto', textAlign:'center'}}>
                        </div>
                    </div>
                    : null}
                    <Typography sx={{mt : 1}}><CalendarMonthIcon sx={{color:'#d72412'}} className='inline-icon-in-navbar'/> {t('dateIns')} : {Moment(freelance.created_at).format('DD/MM/YYYY')}</Typography>
                </Paper>  


            </Grid>

            <Grid item sm={9}>
                        
                <Paper elevation={0}>  
                    <Typography sx={{color : '#d72412', fontWeight :'bold', p:2, width:'95%'}}>{t('Aprops_moi')}
                    
                    { (userData != null && userData.signedIn && userData.user.email == freelance.email) || (userData != null && userData.user.is_admin == 1) ?
                    <Button component={Link} to={`/editeProfile/${freelance.id}`} sx={{height:20, width:200, fontWeight:'900', fontSize:16, float:'inline-end', pt:1}}><ModeEditOutlineIcon className='inline-icon-in-navbar'/> {t('mod_profile')}</Button>
                    :''
                    }

                    </Typography>

                    
                    <Divider sx={{ mt: 1,}}/>
                    {freelance.description == null || freelance.description == 'null' ? <Typography>{t('message_apropos_freelance')}</Typography> : <Typography sx={{mt:1, width:'95%', p:2, justifyContent:'center'}}>{freelance.description}</Typography>} 
                        
                </Paper>
                        

                    
                <Paper sx={{ mt:1}} elevation={0}>
                    {/* tab pour pc */}
                    <Tabs value={valueTabComentMarche} 
                        onChange={handleChangeComentMarcheLink}
                            
                        TabIndicatorProps={{ sx: { background: "#d72412" } }} 
                        sx={{display: { xs: 'none', md: 'flex' }}}
                        >
                        <Tab label={<span>{t('information')}</span>}       sx={{width: '25%', fontWeight: '900',}}/>
                        <Tab label={t('mes_realisation')} sx={{width: '25%', fontWeight: '900',}}/>
                        <Tab label={t('mes_offres')} sx={{width: '25%', fontWeight: '900',}}/>
                        <Tab label={t('commentaires')} sx={{width: '25%', fontWeight: '900',}}/>
                    </Tabs> 

                    {/* tab pour mobile */}
                    <Tabs value={valueTabComentMarche} 
                        onChange={handleChangeComentMarcheLink} 
                        TabIndicatorProps={{ sx: { background: "#d72412" } }} 
                        sx={{display: { lg: 'none', md: 'grid' }}}
                        > 
                        <Tab label={<span>{t('information_mobile')}</span>}  sx={{width: '25%', fontWeight: '900'}}/>
                        <Tab label={t('mes_realisation_mobile')} sx={{width: '28%', fontWeight: '900',}}/>
                        <Tab label={t('mes_offres')} sx={{width: '22%', fontWeight: '900',}}/>
                        <Tab label={t('commentaires_mobile')} sx={{width: '25%', fontWeight: '900',}}/> 
                    </Tabs>

                    <TabPanel value={valueTabComentMarche} index={0}>
                        <Grid container spacing={2} >
                            <Grid item sm={6}>
                                <Paper sx={{p:2}} elevation={0}>
                                    <Typography sx={{ fontWeight :'bold', mb : 2}}>{t('email')}</Typography>
                                    <Typography>{freelance.email}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item sm={6}>
                                <Paper sx={{p:2}} elevation={0}>
                                    <Typography sx={{fontWeight :'bold', mb : 2}}>{t('phone')}</Typography>
                                    <Typography>{freelance.telephone}</Typography> 
                                </Paper>
                            </Grid>
                                
                            <Grid item sm={12} sx={{p:2}}> 
                                <FacebookIcon sx={{p:1, color:'#3b5998'}} onClick={event => (freelance.facebook != null) &&  freelance.facebook.startsWith("https://www.facebook.com") ? window.open(freelance.facebook, '_blank') : Swal.fire({icon: "error", text: t('erreur_fb'),}) }/>
                                <TwitterIcon sx={{p:1, color:'#1D9BF0'}} onClick={event => (freelance.twitter != null) &&  freelance.twitter.startsWith("https://www.twitter.com/") ? window.open(freelance.twitter, '_blank') : Swal.fire({icon: "error", text: t('erreur_twitt'),}) }/>
                                <YouTubeIcon sx={{p:1, color:'#FF0000'}} onClick={event =>  (freelance.youtube != null) &&  freelance.youtube.startsWith("https://www.youtube.com/") ?  window.open(freelance.youtube, '_blank') : Swal.fire({icon: "error", text: t('erreur_youtube'),}) }/> 
                            </Grid> 
                            
                        </Grid> 
                    </TabPanel>

                    <TabPanel value={valueTabComentMarche} index={1}> 
                        <Grid container spacing={2}> 
                            {(userData != null  && userData.signedIn) || (userData != null  && userData.signedIn && userData.user.is_admin == 1 ) ?
                            <Grid item sm={12}>  
                                <Button component={Link} to="/ajouterProject" sx={{float:'inline-end', fontWeight:'900', fontSize:16}}><AddIcon className='inline-icon-in-navbar'/>{t('addProject')}</Button>
                            </Grid>
                            :''}  

                            {portofolio.length == 0 ? <Typography>{t('empty_projets')}</Typography> :
                            <>
                                {portofolio?.map((row) => (
                                    <Grid item sm={4}>
                                        <Freelance_projet id={row.id} photo={row.photo} titre={row.titre}></Freelance_projet>
                                    </Grid>
                                ))} 
                            </>
                            }
                            
                            
                        </Grid>
                    </TabPanel>

                    <TabPanel value={valueTabComentMarche} index={2}> 
                        <Grid container spacing={2}> 
                            {(userData != null  && userData.signedIn) || (userData != null  && userData.signedIn && userData.user.is_admin == 1 ) ?
                            <Grid item sm={12}>  
                                <Button component={Link} to="/ajouterService" sx={{float:'inline-end', fontWeight:'900', fontSize:16}}><AddIcon className='inline-icon-in-navbar'/>{t('addService')}</Button>
                            </Grid>
                            :''}  

                             {offresFreelance.length == 0 ? <Typography>{t('empty_service')}</Typography> :
                            <>
                                {offresFreelance?.map((row) => (
                                    <Grid item sm={4}>
                                        <FreelanceService id={row.id} photo={row.photo} titre={row.titre}></FreelanceService>
                                    </Grid>
                                ))} 
                            </>
                            }
                        </Grid>
                    </TabPanel>

                    <TabPanel value={valueTabComentMarche} index={3}>  
                        {commentaires.map((row) => (
                        <>
                            <Freelance_commentaire nom={row.nom} email={row.email} etoile={row.etoile} commentaire={row.commentaire}
                                                   valider_commentaire={row.valider_commentaire} idCommentaire={row.id}
                                                   ></Freelance_commentaire>
                            <Divider sx={{ m: 1}}/> 

                        </> 
                        ))}
                        
                                                
                    </TabPanel>

                </Paper>
                        
            </Grid>
        </Grid>
    </Container>
     
  );
}