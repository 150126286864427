import React, {useEffect, useState} from 'react';
import api from '../services/api';
import { useParams } from 'react-router-dom' 
import { Grid, Typography, Container, Button, Box } from '@mui/material';  
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import {useNavigate} from 'react-router-dom';  
import PropTypes from 'prop-types';
import Freelance_detail from '../components/freelance_detail';


function TabPanel(props) {
    
    const {
        children,
        value,
        index,
        ...other
    } = props;


    return (
        <div role="tabpanel"
            hidden={
                value !== index
            }
            id={
                `simple-tabpanel-${index}`
            }
            aria-labelledby={
                `simple-tab-${index}`
            }
            {...other}>
            {
            value === index && (
                <Box sx={
                    {p: 3}
                }>
                    <Typography>{children}</Typography>
                </Box>
            )
        } </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Freelance_detail_fo() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const [freelance, setfreelance] = useState({});
    const [freelanceId, setfreelanceId] = useState(0);
    const [portofolio, setportofolio] = useState([]);
    const [offresFreelance, setOffresFreelance] = useState([]);
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [youtube, setYoutube] = useState(''); 
    const [codeCountry, setCodeCountry] = useState(''); 
    const [commentaires, setCommentaires] = useState([]);
    const [valueTabComentMarche, setValueTabComentMarche] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesNumber, setPagesNumber] = useState(1);
   

    const {id} = useParams();

    useEffect(() => {  
        
        handleGetDetailFreelancere(1);

    },[]);

    const paginate = (event, value) => {
        setPageNumber(value);
        handleGetDetailFreelancere(value);
    };

  
    const handleGetDetailFreelancere = (pn) => {   
        api.get(`/api/detaisfreelancers/${id}?page=` +pn, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => { 
            if(response.data != ""){ 
                setfreelance(response.data.freelance) 
                setFacebook(response.data.freelance.facebook)
                setCodeCountry(response.data.freelance.codeCountry)
                setTwitter(response.data.freelance.twitter)
                setYoutube(response.data.freelance.insta)
                setfreelanceId(response.data.freelance.id)
                setportofolio(response.data.portofolio)
                setOffresFreelance(response.data.offres_freelance)
                setCommentaires(response.data.commentaires.data)
                setPagesNumber(response.data.commentaires.last_page)
            }
            else{
                navigate('/')
            }
             
            
            i18n.language == 'fr' ? document.title = response.data.freelance.nom + ' - ' + t(response.data.freelance.domaine) + ' - ' + t('title') : document.title = response.data.freelance.nom + ' - ' + t(response.data.freelance.domaine) + ' - ' + t('title')

        }).catch(error => {
            console.log(error); 
        });
            
    }; 

    const activerfreelance= (e) => {
        e.preventDefault(); 

        Swal.fire ({
            title: 'Wait ...',
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        })

        const formData = {
            'id': freelanceId, 
        } 

        api.post('/api/valider_freelance', formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => { 
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => {
        console.log('error');
        });  
    };

    const desactiverFreelance= (e) => { 
        e.preventDefault(); 

        Swal.fire ({
            title: 'Wait ...',
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        })

        const formData = {
            'id': freelanceId, 
        } 
          
        api.post('/api/desactiver_freelance', formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => { 
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => {
        console.log('error');
        });  
    };

    const supprimer_freelance= (e) => { 
        e.preventDefault(); 

        Swal.fire ({
            title: 'Wait ...',
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        })

        const formData = {
            'id': freelanceId, 
        } 

        api.post('/api/supprimer_freelance', formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => { 
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => { 
        });  
    };   
        
    const retoure_freelance= (e) => {
        e.preventDefault(); 

        Swal.fire ({
            title: 'Wait ...',
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        })

        const formData = {
            'id': freelanceId, 
        } 
      
        api.post('/api/retoure_freelance', formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => { 
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => { 
        });  
    };    
        
    const metter_freelance_acueil= (e) => { 
        e.preventDefault(); 

        Swal.fire ({
            title: 'Wait ...',
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        })
        const formData = {
            'id': freelanceId, 
        } 
            
        api.post('/api/metter_freelance_acueil', formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => { 
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => {
        console.log('error');
        });  
    }; 
        
    const suprimer_freelance_acueil= (e) => { 
        e.preventDefault(); 

        Swal.fire ({
            title: 'Wait ...',
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        })
        const formData = {
            'id': freelanceId, 
        } 
        
        api.post('/api/suprimer_freelance_acueil', formData, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => { 
            Swal.fire({icon: "success", text: response.data.message,})
            navigate("/rim")
        }).catch(error => {
        console.log('error');
        });  
    }; 
    
    

    const handleChangeComentMarcheLink = (event, newValue) => {
        setValueTabComentMarche(newValue);
    };

    
    
  
        
  return ( 
      <Container sx={{mt : 3}}>
         <Grid container spacing={2} >
             <Freelance_detail photo={freelance.photo} nom={freelance.nom} domaine={freelance.domaine} created_at={freelance.created_at} description={freelance.description} email={freelance.email} telephone={freelance.telephone}
                                       facebook={facebook} twitter={twitter} youtube={youtube} id={freelance.id} commentaires={commentaires} portofolio={portofolio} freelanceId={freelanceId} idurl={id} idfreelance={freelance.id} 
                                       pagesNumber={pagesNumber} pageNumber={pageNumber} paginate={paginate} codeCountry={codeCountry} offres_freelance={offresFreelance} noteProfile={freelance.note_profile}
             ></Freelance_detail>

            
            
           
           {/* admin */}
            { userData != null && userData.user.is_admin == 1 && userData.signedIn ?
                <Box sx={{p:5, textAlign : 'center'}}> 
                { freelance.publier == 0 ? <Box component="form"><input hidden value={freelanceId} onChange={(event) => {setfreelanceId(event.target.value)}} /><Button variant="contained" color="success" onClick={activerfreelance}>Valider</Button> <Button variant="contained" color="error" onClick={supprimer_freelance} sx={{mt:2}}>Supprimer</Button></Box>
                    : freelance.publier == 1 ? <Box component="form"><input hidden value={freelanceId} onChange={(event) => {setfreelanceId(event.target.value)}} /><Button variant="contained" color="error" onClick={desactiverFreelance}>Desactiver</Button></Box>
                    : freelance.publier == 2 ? <Box component="form"><input hidden value={freelanceId} onChange={(event) => {setfreelanceId(event.target.value)}} /><Button variant="contained" onClick={retoure_freelance}>Retourner Freelance</Button></Box>
                    : ''
                } 
                <br/><br/>
                {freelance.acueil == 0 && freelance.publier == 1 ? <Box component="form"><input hidden value={freelanceId} onChange={(event) => {setfreelanceId(event.target.value)}} /><Button variant="contained" onClick={metter_freelance_acueil}>Metter au page d'accueil</Button></Box>
                    : freelance.acueil == 1 ? <Box component="form"><input hidden value={freelanceId} onChange={(event) => {setfreelanceId(event.target.value)}} /><Button variant="contained" color="error" onClick={suprimer_freelance_acueil}>Suprimer au page d'accueil</Button></Box>
                : ''
                }
                <br/><br/>
                
                </Box>
                : '' }
            {/* /admin */}

         </Grid>
      </Container>
     
  );
}