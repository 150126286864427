import React, {useState, useEffect} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import api from '../services/api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'; 
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent'; 
import Chip from '@mui/material/Chip';
import PreviewIcon from '@mui/icons-material/Preview';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Divider} from '@mui/material';
import Freelance_commentaire from '../components/freelance_commentaire';
import Freelance_resume from '../components/freelance_resume';



const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Admin() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [avis, setavis] = useState([]);
    const [freelances, setfreelances] = useState([]); 
    const [commentairesNonValider, setCommentairesNonValider] = useState([]); 
    const userData = JSON.parse(window.localStorage.getItem('userData'));

    useEffect(() => { 
      if (userData != null && userData.user.is_admin == 1 && userData.signedIn) {
          navigate('/rim');
      }
      else if(userData != null && userData.user.is_admin !== 1 && userData.signedIn){
          navigate('/');
      }
      else{
         navigate('/login');
      }
      handleGetAllAvis();
    },[]);


    const handleGetAllAvis = () => {   
        api.get('/api/rim', {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {   
           setavis(response.data.avis)
           setfreelances(response.data.freelances)
           setCommentairesNonValider(response.data.commentairesNonValider)
            
        }).catch(error => {
            
            console.log(error); 
        });
         
    }; 

     
    


  return (
    <Container sx={{mt :3}}> 
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography sx={{fontWeight : 'bold'}}>LIST DES ANNONCES NON VALIDER</Typography>
        </AccordionSummary>

        <AccordionDetails>
            <Button variant="contained" component={Link} to="/ListTousAvis" >List des ANNONCES</Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, background : '#eceef0', mt : 1 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="right">Titre fr</TableCell>
                      <TableCell align="right">Titre Ar</TableCell> 
                      <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {avis?.map((row) => (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell scope="row" component={Link} to={`/avis/${row.slug_fr}/${row.id}`}>
                        {row.id}
                    </TableCell>
                    <TableCell align="right" component={Link} to={`/avis/${row.slug_fr}/${row.id}`}>{row.titre_fr}</TableCell>
                    <TableCell align="right" component={Link} to={`/avis/${row.slug_ar}/${row.id}`}>{row.titre_ar}</TableCell> 
                    <TableCell align="right">
                      <Button variant="contained" component={Link} to={`/modifierAvis/${row.id}`}>Edite</Button>
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </AccordionDetails>
      </Accordion>
      <br/>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography sx={{fontWeight : 'bold'}}>LIST DES FREELANCES  NON VALIDER</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Button variant="contained" component={Link} to="/list_Freelance">List freelances</Button>
          <Box sx={{ pl: 3, pr: 3, mt: 2, background : '#eceef0' }}>
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}>
                {freelances?.map((row) => (
                <Grid item md={3} sx={{ width :'100%'}}>
                    <Item elevation={0} >
                        <React.Fragment>
                            <CardContent sx={ {textAlign: 'left'} }>

                                <Typography component="div"  sx={{mb: 2, textAlign : 'center'}}>
                                    {row.photo == null ? <img  src='img/emptyprofile.png' height='140' width='140' alt='profil'/> : 
                                    <img src={'https://link.mr/back/storage/images/' + row.photo} alt='Profile' width="140" height="140" sx={{ width: 56, height: 56, borderRadius: '50%'}} />
                                    }
                                </Typography>

                                <Typography  sx={{ mt: 1,fontWeight : '900', textAlign : 'center'}}>
                                    {row.nom}  
                                </Typography>

                                <Typography  sx={{ mt: 1, textAlign : 'center', color : 'red'}}> 
                                      {row.domaine} 
                                </Typography>

                                <CardActions sx={{width : '100%'}}>
                                  <Typography sx={{width : '100%'}}>  
                                      <Typography sx={{ textAlign : 'center'}}>
                                        <Chip icon={<PreviewIcon/>} label={row.status.view_count} sx={{bgcolor: '#fff',mt: 1,  textAlign : 'center'}}/>
                                      </Typography> 
                                      <Typography  sx={{ textAlign : 'center', mt :1}}> 
                                          <Button component={Link} to={`/freelance/${row.id}`} variant="contained">Profil</Button>
                                      </Typography>
                                  </Typography> 
                                </CardActions>
                                
                            </CardContent>
                        </React.Fragment>
                    </Item>
                </Grid>
                ))}  
              </Grid>
            </Box>
          
        </AccordionDetails>
      </Accordion>

      <br/>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography sx={{fontWeight : 'bold'}}>LIST DES COMMENTAIRE  NON VALIDER</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box sx={{ pl: 3, pr: 3, mt: 2, background : '#eceef0' }}>
              <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}>
                {commentairesNonValider?.map((row) => (
                  <> 
                  <Grid item sm={8} >
                    <Freelance_commentaire nom={row.nom} email={row.email} etoile={row.etoile} commentaire={row.commentaire}
                                           valider_commentaire={row.valider_commentaire} idCommentaire={row.id}
                    ></Freelance_commentaire>
                     
                  </Grid>
                  <Grid item sm={4} >
                    <Freelance_resume nom={row.freelance.nom} domaine={row.freelance.domaine} id={row.freelance.id}></Freelance_resume>

                  </Grid>
                    <Divider sx={{ m: 1, color:'black', width:'90%'}}/>
                     
                  </>
                ))}  
              </Grid>
            </Box>
          
        </AccordionDetails>
      </Accordion>

    </Container>
  );
}
