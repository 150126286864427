import React, {useState, useEffect} from 'react';  
import Typography from '@mui/material/Typography';  
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import api from '../services/api';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import InputLabel from '@mui/material/InputLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Freelance_form_resum from '../components/freelance_form_resum';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

export default function Freelance_complet_profile() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [freelance, setFreelance] = useState({});
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [nni, setNni] = useState(0);
  const [domaine, setDomaine] = useState('');
  const [facebook, setFacebook] = useState('');
  const [youtube, setYoutube] = useState('');
  const [twitter, setTwitter] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('')

  const changeHandler = (event) => {
        setImage(event.target.files[0]);
    };

  const {code} = useParams();

  useEffect(() => {    
        handleGetFreelance();
    },[]);

  
  const handleGetFreelance = () => {   
        api.get(`/api/completre/${code}`).then(response => {   
            setFreelance(response.data.freelance)  
            setNom(response.data.freelance.nom)
            setEmail(response.data.freelance.email)
            setTelephone(response.data.freelance.telephone)
        }).catch(error => {
            console.log(error); 
        });
         
    };

    


  const completreProfile = (e) => { 
    e.preventDefault();  
    
    Swal.fire ({
      title: t('attender'),
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading ()
      }
    })

    api.get('/sanctum/csrf-cookie').then((response) => { 
        const form = new FormData(); 
        form.append('nom', nom);
        form.append('telephone', telephone);
        form.append('nni', nni);
        form.append('domaine', domaine);
        form.append('facebook', facebook);
        form.append('youtube', youtube);
        form.append('twitter', twitter);
        form.append('description', description);
        form.append('image', image); 
        
        api.post( `/api/completreInscription/${code}`, form).then(response => {
            if (response.data.user) {
                const user = response.data.user;
                const token = response.data.token;
                const idFreelance = response.data.idFree;
                const portof = response.data.portof;
                const services = response.data.services;
                window.localStorage.setItem('userData', JSON.stringify({ signedIn: true, user, token, idFreelance, portof, services }));
            } 
            i18n.language == 'fr' ? Swal.fire({icon: "success", text: response.data.msgfr, confirmButtonText: t('ok')}) :  Swal.fire({icon: "success", text: response.data.msgAr,confirmButtonText: t('ok')})
 
            navigate(`/profil`)
        }).catch(error => {
            if(response.data.messagefr != null || response.data.messageAr != null){
            i18n.language == 'fr' ? Swal.fire({icon: "error", text: response.data.error, confirmButtonText: t('ok')}) :  Swal.fire({icon: "error", text: response.data.messageAr,confirmButtonText: t('ok')})
            }
        });
        });
    };

   



  return (
    <Container> 
            <Paper sx={{p: 2, mt:3}}>
                <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 1,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#18689f'}}>
                {t('CompletreInscription')}
                </Typography>  
            </Paper> 
        <form onSubmit={completreProfile} > 
            <Freelance_form_resum photo={image} setImage={(event) => setImage(event.target.files[0])}
                                 nom={nom} setNom={(event) => setNom(event.target.value)}
                                 telephone={telephone} setTelephone={(event) => setTelephone(event)}
                                 email={email} setEmail={(event) => setEmail(event.target.value)}
                                 nni={nni} setNni={(event) => setNni(event.target.value)}
                                 domaine={domaine} setDomaine={(event) => setDomaine(event.target.value)}
                                 facebook={facebook} setFacebook={(event) => setFacebook(event.target.value)}
                                 twitter={twitter} setTwitter={(event) => setTwitter(event.target.value)}
                                 youtube={youtube} setYoutube={(event) => setYoutube(event.target.value)}
                                 description={description} setDescription={(event) => setDescription(event.target.value)}>
            </Freelance_form_resum>
        </form>
   </Container>
  );
}