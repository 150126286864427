import React, {useState} from 'react'; 
import {CardActionArea, Typography} from '@mui/material';     
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom';




const FreelanceService= (props) => {
    const { t, i18n } = useTranslation();  
   

    return ( 
         <CardActionArea component={Link} to={`/service/${props.id}`} sx={{mt:1, ml : 1}}> 
            {props.photo == null ? <img  src='/img/emptyproject.png' height='140' width='100%' alt='Empty'/> : 
            <img src={'https://link.mr/back/storage/images/' + props.photo} alt='Empty' width="218" height="100" />
            }
            <Typography element='body1' sx={{ fontWeight  :'900'}}>{props.titre}</Typography>
        </CardActionArea>
    );
}

export default FreelanceService;
