import React, {useState} from 'react';  
import { Grid, Typography, Rating, Badge, Button, Box } from '@mui/material';  
import { useTranslation } from 'react-i18next';    
import api from '../services/api';




const Freelance_chercer_par_domaine = (props) => {
    const { t, i18n } = useTranslation();  
    
    return ( 
        <Box > 
            <Button className='btn-cherche-par-domaine-acceuil' value='Développement_web' onClick={props.chercher_freelances_par_domaine}> 
                {t('Développement_web')} 
                <Badge color="error" className='badgeExecution badgeFreelance' sx={{m:1}}>{props.countFreelanceDeveloppementWeb}</Badge>
            </Button>
            <Button className='btn-cherche-par-domaine-acceuil ' value='Design' onClick={props.chercher_freelances_par_domaine}>
                {t('Design')}
                <Badge color="error" className='badgeExecution badgeFreelance' sx={{m:1}}>{props.countFreelanceDesign}</Badge>
            </Button>
            <Button className='btn-cherche-par-domaine-acceuil' value='Ventes' onClick={props.chercher_freelances_par_domaine}>
                {t('Ventes')}
                <Badge color="error" className='badgeExecution badgeFreelance' sx={{m:1}}>{props.countFreelanceVentes}</Badge>
            </Button>
            <Button className='btn-cherche-par-domaine-acceuil' value='Redaction' onClick={props.chercher_freelances_par_domaine}>
                {t('Redaction')}
                <Badge color="error" className='badgeExecution badgeFreelance' sx={{m:1}}>{props.countFreelanceRedaction}</Badge>
            </Button>
            <Button className='btn-cherche-par-domaine-acceuil' value='Services_comptables' onClick={props.chercher_freelances_par_domaine}>
                {t('Services_comptables')}
                <Badge color="error" className='badgeExecution badgeFreelance' sx={{m:1}}>{props.freelanceServices_comptables}</Badge>
            </Button>
            <Button className='btn-cherche-par-domaine-acceuil' value='Formation' onClick={props.chercher_freelances_par_domaine}>
                {t('Formation')}
                <Badge color="error" className='badgeExecution badgeFreelance' sx={{m:1}}>{props.countFreelanceFormation}</Badge>
            </Button>
            <Button className='btn-cherche-par-domaine-acceuil' value='Architecture' onClick={props.chercher_freelances_par_domaine}>
                {t('Architecture')}
                <Badge color="error" className='badgeExecution badgeFreelance' sx={{m:1}}>{props.countFreelanceArchitecture}</Badge>
            </Button>
            <Button className='btn-cherche-par-domaine-acceuil' value='Autres' onClick={props.chercher_freelances_par_domaine}>
                {t('Autres')}
                <Badge color="error" className='badgeExecution badgeFreelance' sx={{m:1}}>{props.countFreelanceAutres}</Badge>
            </Button>
         
        </Box>
    );
}

export default Freelance_chercer_par_domaine;
