import React, {useState, useEffect} from 'react';  
import Typography from '@mui/material/Typography';  
import Paper from '@mui/material/Paper'; 
import Grid from '@mui/material/Grid'; 
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import api from '../services/api';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css' 
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';




export default function Freelance_inscrire() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [nom, setNom] = useState('');
  const [telephone, setTelephone] = useState('');
  const [codeCountry, setCodeCountry] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  
  const [validationInput, setValidationInput] = useState('');   
  const [typeCompte, setTypeCompte] = useState(2);
  const [validatioTypeCompte, setValidatioTypeCompte] = useState('');
  const [error, setError] = useState(null);   

  useEffect(() => {  
    i18n.language == 'fr' ? document.title = 'S\'inscrire - ' + t('title')  : document.title = 'حساب جديد - '  + t('title') 
  },[]);

  
const vd = /\S+@\S+\.\S+/.test(email);
  
  const handleSaveFreelance = (e) => {
    e.preventDefault();

    
    if((nom.trim().length != 0) && (telephone.trim().length != 0) && (email.trim().length != 0) && (password.trim().length != 0) && (typeCompte == 0 || typeCompte == 1)){
         if(!vd){
          setError(t('validatoin_email'));
         }
         else{
          setValidationInput('')    
        Swal.fire ({ 
          title : t('attender'),
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading ()
          }
        })

        
        const form = new FormData();
        form.append('nom', nom);
        form.append('email', email);
        form.append('password', password);
        form.append('telephone', telephone);
        form.append('codeCountry', codeCountry);
        form.append('typeCompte', typeCompte); 

        if(i18n.language == 'fr'){
            form.append('nom_fr', nom);   
        }
        else{
            form.append('nom_ar', nom);   
        }
    
        api.post('/api/inscrir', form, {
            headers: {
                'Accept': 'application/json', 
            }
        }).then(response => {  

          console.log(response)

            if(response.data.messagefr != null || response.data.messageAr  != null){
              i18n.language == 'fr' ? Swal.fire({icon: "success", text: response.data.messagefr,confirmButtonText: t('ok'),}) :  Swal.fire({icon: "success", text: response.data.messageAr,confirmButtonText: t('ok'),}) 
              navigate("/") 
            }
            else if(response.data.mgAr != null || response.data.mgFr  != null){
              i18n.language == 'fr' ? Swal.fire({icon: "error", text: response.data.mgFr,confirmButtonText: t('ok'),}) :  Swal.fire({icon: "error", text: response.data.mgAr,confirmButtonText: t('ok'),}) 
            }
            else{
                i18n.language == 'fr' ? Swal.fire({icon: "error", text: response.data.msgfr,confirmButtonText: t('ok'),}) :  Swal.fire({icon: "error", text: response.data.msgAr,confirmButtonText: t('ok'),}) 
              navigate("/inscrire")
            }
            
        }).catch(error => {
          
        }); 
         }
        
     }else{
      setValidationInput(t('validation_champ')) 
      setValidatioTypeCompte(t('validation_type_compte')) 
     }

  }; 

  function handleOnChange(value,country) { 
    setTelephone(value);  
    setCodeCountry(country.countryCode);   
  }


  const handleChange = (event) => {
    setTypeCompte(event.target.value);
  };

  

  return (
   <>
   
    <Container>   
          <Paper  sx={{p: 2, m:2}}>  
              <Typography sx={{ bgcolor: '#fff',mt: 3, textAlign : 'center'}}>{t('insc_independant')}</Typography>
              <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 3,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#18689f'}}>
                    {t('inscrit')}
              </Typography>   
                 <div style={{textAlign:'center', margin:7}}>
                    <Radio
                      checked={typeCompte == '0'}
                      onChange={handleChange}
                      value="0"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                    <FormLabel>{t('inscrit_freelance')}</FormLabel>
                    
                    
                    <Radio
                      checked={typeCompte == '1'}
                      onChange={handleChange}
                      value="1"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'B' }}
                    />
                    <FormLabel>{t('inscrit_porteur_projet')}</FormLabel>
                    {typeCompte != 2 ? '' : <Typography sx={{fontSize:13, color:'red'}}>{validatioTypeCompte}</Typography>}
                 </div>
                
                  <Grid container spacing={2} > 
                    <Grid item md={6} sx={{width : '100%'}}>  
                      <TextField id="outlined-required" 
                        label={t('nom')}
                        variant="outlined"
                        sx={{width : '100%'}} 
                        value={nom}  
                        onChange={(event) => {setNom(event.target.value)}} 
                      /> 
                      {nom.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>  
                      <PhoneInput country='mr' variant="outlined" placeholder='' value={telephone}
                            preferredCountries={['mr', 'ma', 'dz', 'tn','ly', 'sa', 'jo', 'qa', 'ae', 'kw', 'iq', 'om', 'ps', 'sd']}
                            specialLabel={t('phone')}
                            onChange={handleOnChange} 
                            style={{width:'100%'}} />


                      {telephone.trim().length !== 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}

                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                      <TextField id="outlined-uncontrolled" 
                        required
                        label={t('email')}
                        variant="outlined"
                        sx={{width : '100%'}}  
                        value={email}
                        onChange={(event) => {setEmail(event.target.value); if(vd){setError(null);}}} 
                      />
                      {email.trim().length !== 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                      <Typography sx={{fontSize:12, color:'red'}}>{error}</Typography>
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                      <TextField id="outlined-uncontrolled" 
                        required
                        label={t('password')}
                        variant="outlined"
                        sx={{width : '100%'}} 
                        value={password}
                        type="password"
                        onChange={(event) => {setPassword(event.target.value)}} 
                      />
                      {password.trim().length !== 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                    </Grid>

                    <Grid item md={12} sx={{width : '100%', textAlign : 'center'}}>
                      
                      <Button type="submit" className='btn' variant="contained" onClick={handleSaveFreelance}>
                        {t('envoyer')}  
                      </Button>

                    
                    </Grid>
                    

                  </Grid>
               
          </Paper>

   </Container>
   </>
  );
}