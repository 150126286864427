import React from 'react';  
import { Grid, Typography, Paper, Divider, Button, styled, CardActions, CardContent, Box } from '@mui/material';  
import { useTranslation } from 'react-i18next';   
import { Link } from 'react-router-dom'; 
import Notes from './notes';  
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Freelance_resume = (props) => {
    
    const { t, i18n } = useTranslation(); 
    

     
    return (  
        <Item elevation={0} >  
            <CardContent sx={ {textAlign: 'left', minHeight : 230} }>


                {/* <div style={{position: 'absolute', width: '100px', height: '100px'}}>
                    <div className='progress-profil'>
                        <CircularProgressbar value={props.noteProfile} text='kkk' />
                        </div>
                    </div> */}

                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgressbar className='progress-profil' value={props.noteProfile}  />    
                        <Typography position='absolute' component="div" sx={{ textAlign : 'center'}}>
                            {props.photo == null ? <img className='image' src='/img/emptyprofile.png'  alt='profil'/> : 
                            <img className='image' src={'https://link.mr/back/storage/images/' + props.photo}  />
                            }
                        </Typography>
                    </Box>

                {/* <Typography component="div" sx={{mb: 2, textAlign : 'center'}}> 
                    <CircularProgressbar className='progress-profil' value={props.noteProfile} text="pp" />    
                </Typography> */}
                {/* <Typography component="div" sx={{mb: 2, textAlign : 'center'}}>
                    {props.photo == null ? <img className='image' src='/img/emptyprofile.png'  alt='profil'/> : 
                    <img className='image im-in-free' src={'https://link.mr/back/storage/images/' + props.photo}  />
                    }
                </Typography> */}
                <Typography  sx={{ mt: 1,fontWeight : '900', textAlign : 'center', fontSize:'15px'}}>
                        {props.nom} 
                </Typography>
                <Typography  sx={{ mt: 1, textAlign : 'center', color : '#d72412', fontSize:'12px', height:'40px'}}> 
                    {t(props.domaine)}
                </Typography> 
                {props.codeCountry != null ?
                    <div className=' react-tel-input' style={{direction:'inherit'}}>
                        <div className={ `flag ` + props.codeCountry} style={{margin:'auto', textAlign:'center'}}>
                        </div>
                    </div>
                : null}
            </CardContent>

            <Divider sx={{ mt: 1,}}/> 
            
            <CardActions sx={{width : '100%'}}>
                
                
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }} >
                    <Grid item md={12} sx={{ width :'100%'}}>
                        <Notes count_commentaire={props.commentaire_count} count_Vsbtly={props.view_count} valueRating={props.commentaire_avg_etoile}/>
                    </Grid> 
                    <Grid item md={12} sx={{ width :'100%'}}> 
                        <Button className='btn' component={Link} to={`/freelance/${props.id}`} variant="contained">{t('profil')}</Button>
                    </Grid>
                </Grid> 

            </CardActions>
                
        </Item>
    );
}

export default Freelance_resume;
