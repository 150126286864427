import React, {useState, useEffect} from 'react';  
import Typography from '@mui/material/Typography';  
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import api from '../services/api';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import InputLabel from '@mui/material/InputLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Freelance_form_resum from '../components/freelance_form_resum';

export default function Freelance_edit_profile() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [freelance, setFreelance] = useState({});
    const [idFree, setIdFree] = useState();
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [nni, setNni] = useState();
    const [domaine, setDomaine] = useState('');
    const [facebook, setFacebook] = useState('');
    const [youtube, setYoutube] = useState('');
    const [twitter, setTwitter] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('')
    const userData = JSON.parse(window.localStorage.getItem('userData'));  
 

    

    const {id} = useParams();  

    useEffect(() => {  
        handleGetFreelance();
        i18n.language == 'fr' ? document.title = 'Modifier profile - ' + t('title')  : document.title = 'تعديل الملف الشخصي - ' + t('title')         
    },[]);

  
    const handleGetFreelance = (event) => {      
        if((userData != null &&  userData.idFreelance == id ) || (userData != null && userData.user.is_admin == 1)){ 
            api.get(`/api/editeProfile/${id}`, {
                headers: {
                "Accept": "application/json", 
                }
            }).then(response => { 
                setFreelance(response.data.freelance)  
                setIdFree(response.data.freelance.id)
                setNom(response.data.freelance.nom)
                setEmail(response.data.freelance.email)
                setTelephone(response.data.freelance.telephone)
                setNni(response.data.freelance.nni)
                setDomaine(response.data.freelance.domaine)
                setFacebook(response.data.freelance.facebook)
                setTwitter(response.data.freelance.twitter)
                setYoutube(response.data.freelance.insta)
                setDescription(response.data.freelance.description)
                setImage(event.target.files[0]); 
                
            }).catch(error => {
                
            });
        }
        else{
        navigate('/') 
        }
    };

   
    
    const handleEditeFreelance = (e) => { 
        e.preventDefault();

        Swal.fire ({
            title: t('attender'),
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        }) 
        
        api.get('/sanctum/csrf-cookie').then((response) => { 
            const form = new FormData();
            form.append('id', idFree);
            form.append('nom', nom);
            form.append('telephone', telephone);
            form.append('nni', nni);
            form.append('domaine', domaine);
            form.append('facebook', facebook);
            form.append('youtube', youtube);
            form.append('twitter', twitter);
            form.append('description', description);
            form.append('image', image);
            
            api.post( `/api/modifierProfile`, form).then(response => { 
                console.log(response.data);
                i18n.language == 'fr' ? Swal.fire({icon: "success", text: response.data.messagefr,confirmButtonText: t('ok')}) :  Swal.fire({icon: "success", text: response.data.messageAr,confirmButtonText: t('ok')})               
                navigate(`/profil`)
            }).catch(error => { 
            });
        });
    };


    


  return ( 
    <Container> 
            <Paper sx={{p: 2, mt:3}} elevation={0}>
                <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 1,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#1976d2'}}>
                    {t('mod_profile')}
                </Typography>
            </Paper> 

        <form onSubmit={handleEditeFreelance} > 
            <Freelance_form_resum photo={image} setImage={(event) => setImage(event.target.files[0])}
                                 nom={nom} setNom={(event) => setNom(event.target.value)}
                                 telephone={telephone} setTelephone={(event) =>  setTelephone(event)}
                                 email={email} setEmail={(event) => setEmail(event.target.value)}
                                 nni={nni} setNni={(event) => setNni(event.target.value)}
                                 domaine={domaine} setDomaine={(event) => setDomaine(event.target.value)}
                                 facebook={facebook} setFacebook={(event) => setFacebook(event.target.value)}
                                 twitter={twitter} setTwitter={(event) => setTwitter(event.target.value)}
                                 youtube={youtube} setYoutube={(event) => setYoutube(event.target.value)}
                                 description={description} setDescription={(event) => setDescription(event.target.value)}>
            </Freelance_form_resum>
        </form>    
    
    </Container> 
  );
}