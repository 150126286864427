import React, {useEffect, useState} from 'react';
import api from '../services/api';
import { Link, useParams,} from 'react-router-dom'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import { Pagination, Stack, TextField, Typography } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import {Divider} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';  
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function Avis_detail() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [job, setjob] = useState({}); 
  const [jobId, setjobId] = useState();
  const [etat, setetat] = useState(0);
  const [domaine, setDomanie] = useState('');
  const [societe, setsociete] = useState({});
  const [skill, setskill] = useState([]);
  const [freelances, setFreelances] = useState([]);
  const [freelance, setFreelance] = useState({});
  const [freelanceAffacterAvis, setFreelanceAffacterAvis] = useState({});
  const [freelanceId, setFreelanceId] = useState(); 
  const [societeEmail, setsocieteEmail] = useState('');
  const {slug, id} = useParams();
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const [pageNumber, setPageNumber] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(1);
  const [list, setList] = useState([0]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  

  useEffect(() => {   
    handleGetDetailAvis();  
  },[]);

  
  const handleGetDetailAvis = () => {   
      api.get(`/api/avis/${slug}/${id}`, {
          headers: {
          "Accept": "application/json", 
          }
      }).then(response => { 
        
          setjob(response.data.job)
          setjobId(response.data.job.id) 
          setsociete(response.data.s)
          setsocieteEmail(response.data.s.email)
          setskill(response.data.skill) 
          setFreelanceAffacterAvis(response.data.freelance)
          // setStatus(response.data.freelance.status)
           console.log('response.data free ') 
            console.log(response.data) 
          i18n.language == 'fr' ? document.title = (response.data.job.titre_fr + ' - ' + t('title') ) : document.title = (response.data.job.titre_ar + ' - ' + t('title') )
          if(response.data != ''){
            api.get(`/api/getfreelanceByDomaine/${response.data.job.domaine}`, {
                headers: {
                "Accept": "application/json", 
                }
            }).then(response => { 
              setFreelances(response.data.freelances) 
            }).catch(error => {
                console.log(error); 
            });  
          } else{
            return null;
          }

      }).catch(error => {
          console.log(error); 
      });
         
  };



  var detailAvis  = {}

  i18n.language == 'fr' ? 
    detailAvis = {
      'id' : job.id,
      'titre' : job.titre_fr,
      'slug' : job.slug_fr,
      'etat' : job.etat,
      'description' : job.description_fr, 
      'periode' : job.periode,
      'budget_discute' : job.budget_discute,
      'budget_min' : job.budget_min,
      'budget_max' : job.budget_max,
      'ouvert' : 'Ouvert',
      'execution' : 'En execution',
      'ferme' : 'Ferme',
      'Adiscute' : 'A discute',
      'budget' : 'Budget',
      'nomSociete' : societe.nom_fr,
      'experience' : job.experience,
      'domaine' : job.domaine,
    } 
    :  
    detailAvis = {
       'id' : job.id,
        'titre' : job.titre_ar,
        'slug' : job.slug_ar,
        'etat' : job.etat,
        'description' : job.description_ar, 
        'periode' : job.periode,
        'budget_discute' : job.budget_discute,
        'budget_min' : job.budget_min,
        'budget_max' : job.budget_max,
        'ouvert' : 'مفتوح',
        'execution' : 'قيد التنفيذ',
        'ferme' : 'مغلق',
        'Adiscute' : 'يناقش',
        'budget' : 'الميزانية',
        'nomSociete' : societe.nom_ar,
        'experience' : job.experience,
        'domaine' : job.domaine,
    }

    const detailSkill = (skill.map((row) => {
        if(i18n.language == 'fr'){
            return({
                'id' : row.id,
                'nom' : row.nom_fr, 
            })
        }
        else {
            return ({
                'id' : row.id,
                'nom' : row.nom_ar,
            })
        } 
    }))
   


    
  const validerAvis= (e) => { 
    e.preventDefault(); 
    Swal.fire ({title: 'Wait ...', showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading ()
      }
    })
    const formData = {'jobId': jobId, }
    api.post('/api/valider_Avis', formData, {
        headers: {
            'Accept': 'application/json', 
        }
    }).then(response => { 
      console.log('response.data valid')
      console.log(response.data)
        Swal.fire({icon: "success", text: response.data.message,})
        // navigate("/")
    }).catch(error => {
      console.log('error');
    }); 

  };

  const desactiverAvis= (e) => { 
    e.preventDefault(); 

    Swal.fire ({title: 'Wait ...', showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading ()
      }
    })

    const formData = {'jobId': jobId, } 
   
    api.post('/api/desactiver_Avis', formData, {
        headers: {
            'Accept': 'application/json', 
        }
    }).then(response => { 
        Swal.fire({icon: "success", text: response.data.message,})
        navigate("/")
    }).catch(error => {
      console.log('error');
    }); 

  };

  const supprimerAvis= (e) => { 
    e.preventDefault(); 

    Swal.fire ({title: 'Wait ...', showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading ()
      }
    })

    const formData = {'jobId': jobId,}
       
    api.post('/api/suprimer_avis', formData, {
        headers: {
            'Accept': 'application/json', 
        }
    }).then(response => { 
        
        Swal.fire({icon: "success", text: response.data.message,})
        navigate("/rim")
    }).catch(error => {
      console.log('error');
    }); 

  };

  const activerAvis= (e) => { 
    e.preventDefault(); 

    Swal.fire ({title: 'Wait ...', showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading ()
      }
    })

    const formData = {'jobId': jobId,}
      
    api.post('/api/activer_Avis', formData, {
        headers: {
            'Accept': 'application/json', 
        }
    }).then(response => { 
        
        Swal.fire({icon: "success", text: response.data.message,})
        navigate("/rim")
    }).catch(error => {
      console.log('error');
    }); 

  };

  const changer_etat_avis= (e) => { 
    e.preventDefault(); 

    Swal.fire ({title: 'Wait ...', showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading ()
      }
    })

    const formData = {
        'jobId': jobId, 
        'etat': etat, 
        'freelanceId': freelanceId, 
    } 
     
    api.post('/api/chang_etat_projet', formData, {
        headers: {
            'Accept': 'application/json', 
        }
    }).then(response => { 
        Swal.fire({icon: "success", text: response.data.message,})
        // handleGetDetailAvis();
        navigate("/")
    }).catch(error => {
      console.log('error');
    }); 

  };

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = freelances.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      console.log(freelances)
      setFilteredResults(filteredData);
    } else {
      setFilteredResults([]);
    }
  };

  const selectItems = (id) => {
    // const id = e.target.value;
    const fr = freelances.find((item) => item.id == id);
    setFreelance(fr)
    setFreelanceId(fr.id)
  };

  
  return (
    <> 
      <Container >
        
          <Paper elevation={0}  sx={{mt:1.5, pt:1, pb:1, display: {sm: 'none'}}} >
              {/* afichage telephone */}
            <Stepper> 
              <Step active={true} >
                <StepLabel ></StepLabel>
                <Typography sx={{fontWeight : 'bold', fontSize : 10}}  >{t('etat1')}</Typography> 
                
              </Step>
              <Step active={job.etat == 30 || job.etat > 30 ? true : ''} >
                <StepLabel></StepLabel> 
                <Typography sx={{fontWeight : 'bold', fontSize : 10}}>{t('etat2')}</Typography>
              </Step>
              <Step active={job.etat > 50 || job.etat == 100 ? true : ''}>
                <StepLabel></StepLabel> 
                <Typography sx={{fontWeight : 'bold', fontSize : 10}}>{t('etat3')}</Typography>
              </Step> 
            </Stepper>
            
          </Paper>
          {/* affchage pc */}
          <Paper elevation={0} sx={{mt:1.5, p: 5, display: { xs: 'none', lg: 'block', xl: 'none'} }}>
              <Stepper> 
                <Step active={true} >
                  <StepLabel><Typography sx={{fontWeight : 'bold', p : 1}}  >{t('etat1')} </Typography></StepLabel>
                   
                </Step>
                <Step active={job.etat == 30 || job.etat > 30 ? true : ''} >
                  <StepLabel><Typography sx={{fontWeight : 'bold', p : 1}}> {t('etat2')} </Typography></StepLabel> 
                  
                </Step>
                <Step active={job.etat == 100 ? true : ''}>
                  <StepLabel> <Typography sx={{fontWeight : 'bold', p : 1}}> {t('etat3')} </Typography></StepLabel> 
                 
                </Step> 
            </Stepper>
          </Paper>
      </Container>

    
    <Container sx={{mt : 1}}> 
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
            
          <Typography component="div" sx={{textAlign : 'center', color:'#18689f', fontWeight : 'bold', fontSize : 25, pb:1}}>
              {detailAvis.titre}
          </Typography>
          <Typography sx={{textAlign : 'center'}}>{t(detailAvis.domaine)}</Typography>

          <Divider/> 

          <Typography  align='justify' sx={{ mt: 2 }} >
              {detailAvis.description}
          </Typography>

          <Typography sx={{mt:2}} style={{display:'flex'}}>
             <BusinessIcon className='inline-icon-in-navbar' />&nbsp;
             {/* {societe.codeCountry != null ? 
                <div className=' react-tel-input' style={{width:'auto'}}>
                    <div className={ `flag ` + societe.codeCountry} >
                    </div>
                </div>  
              : null} */}

              {detailAvis.nomSociete} 

          </Typography>

          <Typography sx={{mt:1}}>
            <MailOutlineIcon className='inline-icon-in-navbar'/> {societe.email} 
          </Typography>

          <Typography sx={{mt:1}}>
              <PhoneAndroidIcon className='inline-icon-in-navbar'/> {societe.telephone}  
          </Typography>
          

        </CardContent>

        <CardActions>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6} sm={6} sx={{ width : '100%'}}>
              <Paper sx={{p:2, background : '#f1f4fa', minHeight : 85}}>
              <Typography sx={{fontWeight : 'bold', fontSize : 18}}>{t('budget')}</Typography>
                {job.budget_discute == 1 ? <Typography>{detailAvis.Adiscute}</Typography> : <Typography>[{t('min')}:{job.budget_min} - {t('max')}:{job.budget_max}]</Typography> }
              </Paper>
            </Grid>
            <Grid item lg={4} md={6} sm={6} sx={{ width : '100%'}}>
              <Paper sx={{p:2, background : '#f1f4fa', minHeight : 85}}>
              <Typography sx={{fontWeight : 'bold', fontSize : 18}}>{t('duree_prg')}</Typography>
                <Typography>{t(detailAvis.periode)}</Typography>
              </Paper>
            </Grid>
            
            <Grid item lg={4} md={6} sm={6} sx={{ width : '100%'}}>
              <Paper sx={{p:2, background : '#f1f4fa', minHeight : 85}} >
              <Typography sx={{fontWeight : 'bold', fontSize : 18}}>{t('exprience')}</Typography>
                <Typography>{t(detailAvis.experience)}</Typography>
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={6} sx={{ width : '100%'}}>
              <Paper sx={{p:2, background : '#f1f4fa', minHeight : 85}}>
                <Typography sx={{fontWeight : 'bold', fontSize : 18, textAlign:'center'}}>{t('comptence')}</Typography>

                <Grid container spacing={2}>
                  {detailSkill?.map((row) => (
                  <Grid item lg={4} md={6} sm={6} sx={{ width : '100%'}}>
                      <Typography sx={{borderRight:'1px black solid'}}>{row.nom} </Typography>
                  </Grid>
                  ))} 
                </Grid>
              </Paper>
            </Grid>
            
            {job.freelance_id != null ? 
              <Grid item lg={6} md={6} sm={6} sx={{ width : '100%'}}>
                <Paper sx={{p:2, background : '#f1f4fa', minHeight : 85}}>
                <Typography sx={{fontWeight : 'bold', fontSize : 18, textAlign:'center'}}> {t('freelance_affectée')} </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} sx={{ width : '100%'}}>
                    <Typography component="div"  sx={{textAlign : 'center'}}> 
                        {freelanceAffacterAvis.photo == null ? <img className='image' src='/img/emptyprofile.png' height='70' width='70' alt='profil'/> : 
                        <img className='image' src={'https://link.mr/back/storage/images/' + freelanceAffacterAvis.photo} alt='Profile' width="70" height="70" sx={{ width: 56, height: 56, borderRadius: '50%'}} />
                        }
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} sx={{ width : '100%'}}>
                      <Typography  sx={{ mt: 1,fontWeight : '900', textAlign : 'center', fontSize:'15px'}}>
                          {freelanceAffacterAvis.nom} 
                      </Typography>
                      <Typography  sx={{ mt: 1, textAlign : 'center', color : '#d72412', fontSize:'12px', height:'40px'}}> 
                          {t(freelanceAffacterAvis.domaine)}
                      </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} sx={{ width : '100%', textAlign:'center'}}>
                    <Button className='btn' component={Link} to={`/freelance/${freelanceAffacterAvis.id}`} variant="contained">{t('profil')}</Button>
                  </Grid>
                </Grid>
                </Paper>
              </Grid> 
            : null}
        </Grid>
        </CardActions>

      </Card>

    </Container>
     

    {(userData != null && userData.user.is_admin == 1 && userData.signedIn) ? 
      <Container sx={{display : 'block', p:5, textAlign : 'center'}}>
        { job.valider == 0 && job.active == 1 ?  <Box component="form"><input hidden value={jobId} onChange={(event) => {setjobId(event.target.value)}} /><Button variant="contained" color="success" onClick={validerAvis}>Valider</Button></Box>
         : job.valider == 1 ? <Box component="form"><input hidden value={jobId} onChange={(event) => {setjobId(event.target.value)}} /><Button variant="contained"  color="error" onClick={desactiverAvis}>Desactiver</Button></Box>
         : ''
         
        }
         <br/><br/>
        { job.valider == 0 && job.active == 0 ? <Box component="form"><input hidden value={jobId} onChange={(event) => {setjobId(event.target.value)}} /><Button variant="contained" onClick={activerAvis}>Activer</Button></Box>
        : job.valider == 0 && job.active == 1 ? <Box component="form"><input hidden value={jobId} onChange={(event) => {setjobId(event.target.value)}} /><Button variant="contained" color="error" onClick={supprimerAvis}>Supprimer</Button></Box>
        : '' }
        
        <br/><br/>
        <br/><br/>
        { job.valider == 1 && job.active == 1  ?
            <Box component="form">
                <input hidden value={jobId} onChange={(event) => {setjobId(event.target.value)}} />
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} sx={{ width : '100%'}}>

                    <FormControl fullWidth sx={{background : '#fff'}}> 
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select" 
                        value={etat} 
                        onChange={(event) => {setetat(event.target.value)}}
                        >
                        <MenuItem value='0'>Etape de réception des offres</MenuItem>
                        <MenuItem value='50'>Phase de mise en oeuvre</MenuItem>
                        <MenuItem value='100'>Etape de reception</MenuItem> 
                      </Select>
                    </FormControl>

                    <div> 
                        <Button sx={{ mt:'3px'}} className='btn-change-etat-avis' variant="contained" onClick={changer_etat_avis}>{t('change_etat_projet')}</Button>
                    </div>

                    {etat == 50 ?
                
                    Object.keys(freelance).length !== 0 ? (
                      <>
                        <TextField id="outlined-basic" label='Entree le debut du nom freelance selectionne'
                        fullWidth sx={{background : '#fff', mt:4}}
                        value={freelance.nom}  
                          onChange={(e) => {
                            setFreelance({});
                            searchItems(e.target.value);
                          }} />
                      </>
                    ) : (
                      <>
                        <TextField id="outlined-basic" label='Entree le debut du nom freelance selectionne'
                        fullWidth sx={{background : '#fff', mt:'10px'}}
                        onChange={(e) => {
                            searchItems(e.target.value);
                          }} />

                        {searchInput.length > 2 ? (
                          <div className="flex-nowrap overflow-auto">
                            <div sx={{ maxHeight: "70px" }}>
                              
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                          <TableHead sx={{background:'#eee'}}>
                                            <TableRow>
                                              <TableCell>{t('nom')}</TableCell>
                                              <TableCell align="right">{t('email')}</TableCell>
                                              <TableCell align="right">{t('domaine')}</TableCell>
                                              <TableCell align="right">{t('photo')}</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {filteredResults.map((item) => {
                                                return (
                                              <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => selectItems(item.id)}>
                                                <TableCell component="th" scope="row"> {item.nom}</TableCell>
                                                <TableCell align="right">{item.email}</TableCell>
                                                <TableCell align="right">{t(item.domaine)}</TableCell>
                                                <TableCell align="right">
                                                  <Typography component="div"  sx={{mb: 2, textAlign : 'center'}}>
                                                      {item.photo == null ? <img className='image' src='/img/emptyprofile.png' height='50' width='50' alt='profil'/> : 
                                                      <img className='image' src={'https://link.mr/back/storage/images/' + item.photo} alt='Profile' width="50" height="50" sx={{ width: '50px', height: '50', borderRadius: '50%'}} />
                                                      }
                                                  </Typography>
                                                </TableCell>
                                                
                                              </TableRow> 

                                                );
                                                })}
                                          </TableBody>
                                        </Table>
                                  </TableContainer>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                      )
                    : null
                    }


                  </Grid>
                   
                </Grid>
            </Box>
                : '' 
        }       
      </Container>
      : ''
    }

   {(userData != null && userData.user.type == 1 && userData.signedIn) && (userData.socite.email == societe.email) ? 
      <Container sx={{display : 'block', p:5, textAlign : 'center'}}> 
      <Box component="form" sx={{mb:3}}>
        <input hidden value={jobId} onChange={(event) => {setjobId(event.target.value)}} />
        <Button variant="contained"  color="error" onClick={desactiverAvis}>{t('desactiver')}</Button>
      </Box>
        
        { job.valider == 1 && job.active == 1 && job.valider == 1 ?
            <Box component="form">
              <input hidden value={jobId} onChange={(event) => {setjobId(event.target.value)}} />

              <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} sx={{ width : '100%'}}>

                      <FormControl fullWidth sx={{background : '#fff'}}> 
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select" 
                                value={etat} 
                                onChange={(event) => {setetat(event.target.value)}} >

                                <MenuItem value='0'>{t('etap_recption_offre')}</MenuItem>
                                <MenuItem value='50'>{t('phase_de_mise_en_oeuvre')}</MenuItem>
                                <MenuItem value='100'>{t('etap_reception')}</MenuItem> 
                              </Select>
                      </FormControl>
                      <div> 
                        <Button sx={{ mt:'3px'}} className='btn-change-etat-avis' variant="contained" onClick={changer_etat_avis}>{t('change_etat_projet')}</Button>
                      </div>

                      {etat == 50 ?
                      
                      Object.keys(freelance).length !== 0 ? (
                        <>
                          <TextField id="outlined-basic" label='Entree le debut du nom freelance selectionne'
                          fullWidth sx={{background : '#fff', mt:4}}
                          value={freelance.nom}  
                            onChange={(e) => {
                              setFreelance({});
                              searchItems(e.target.value);
                            }} />
                        </>
                      ) : (
                        <>
                          <TextField id="outlined-basic" label={t('Entree_nom_free')}
                            fullWidth sx={{background : '#fff', mt:'10px'}}
                            onChange={(e) => {
                                searchItems(e.target.value);
                              }} />
                          {searchInput.length > 2 ? (
                            <div className=" flex-nowrap overflow-auto">
                              <div style={{ height: "100px" }}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                          <TableHead sx={{background:'#eee'}}>
                                            <TableRow>
                                              <TableCell>{t('nom')}</TableCell>
                                              <TableCell align="right">{t('email')}</TableCell>
                                              <TableCell align="right">{t('domaine')}</TableCell>
                                              <TableCell align="right">{t('photo')}</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {filteredResults.map((item) => {
                                                return (
                                              <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => selectItems(item.id)}>
                                                <TableCell component="th" scope="row"> {item.nom}</TableCell>
                                                <TableCell align="right">{item.email}</TableCell>
                                                <TableCell align="right">{t(item.domaine)}</TableCell>
                                                <TableCell align="right">
                                                  <Typography component="div"  sx={{mb: 2, textAlign : 'center'}}>
                                                      {item.photo == null ? <img className='image' src='/img/emptyprofile.png' height='50' width='50' alt='profil'/> : 
                                                      <img className='image' src={'https://link.mr/back/storage/images/' + item.photo} alt='Profile' width="50" height="50" sx={{ width: '50px', height: '50', borderRadius: '50%'}} />
                                                      }
                                                  </Typography>
                                                </TableCell> 
                                              </TableRow> 

                                                );
                                                })}
                                          </TableBody>
                                        </Table>
                                  </TableContainer>

                                  </div>
                                </div>
                              ) : (
                                "" 
                              )}
                            </>
                            )
                          : null
                          }

                  </Grid>
                  <Grid item lg={2} md={12} sm={12} sx={{ width : '100%'}}></Grid>
              </Grid>


                
            </Box>
                : '' 
        }    

           
      </Container>
    :''
    }
    </>
  );
}