import React, {useState} from 'react';  
import { Grid, Typography, Paper } from '@mui/material';  
import { useTranslation } from 'react-i18next';    
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';  
import Swal from 'sweetalert2';
import api from '../services/api';




const Freelance_form_commentaire = (props) => {
    const { t, i18n } = useTranslation(); 
    const [valueRatings, setValueRatings] = useState(0);
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState(''); 
    const [commentaire, setCommentaire] = useState('');
    const [freelances_ids, setFreelances_ids] = useState([]);
    const [maxLenghtTextarea, setMaxLenghtTextarea] = useState('');

    // window.sessionStorage.removeItem('email_sa7b_commentaire'); 
    const commenter = (e) => { 
        e.preventDefault();  
        Swal.fire ({
            title: t('attender'),
            showConfirmButton: false,
            onBeforeOpen: () => {
            Swal.showLoading ()
            }
        })
 
        const form = new FormData(); 
        form.append('nom', nom);
        form.append('email', email);
        form.append('valueRatings', valueRatings);
        form.append('commentaire', commentaire);
        form.append('freelanceId', props.freelanceId);

        
        
        const emailInSession = JSON.parse(window.sessionStorage.getItem('email_sa7b_commentaire'))    

        if(emailInSession == null){
            api.post( `/api/commenter`, form).then(response => {   
            freelances_ids.push(parseInt(props.idurl));
            window.sessionStorage.setItem('email_sa7b_commentaire', JSON.stringify({ email, freelances_ids}));
                 
            i18n.language == 'fr' ? Swal.fire({icon: "success", text: response.data.commentFr, confirmButtonText: t('ok')}) :  Swal.fire({icon: "success", text: response.data.commentAr,confirmButtonText: t('ok')})
                
            }).catch(error => {
            console.log(error);
            });
        }
        else if(emailInSession != null && !emailInSession.freelances_ids.includes(parseInt(props.idurl))){
            api.post( `/api/commenter`, form).then(response => {   
            freelances_ids.push(parseInt(props.idurl));
            window.sessionStorage.setItem('email_sa7b_commentaire', JSON.stringify({ email, freelances_ids}));
            i18n.language == 'fr' ? Swal.fire({icon: "success", text: response.data.commentFr, confirmButtonText: t('ok')}) :  Swal.fire({icon: "success", text: response.data.commentAr,confirmButtonText: t('ok')})
                 
            }).catch(error => {
            console.log(error);
            });
        }else{
            i18n.language == 'fr' ? Swal.fire({icon: "success", text: 'Vous déja commenté ce freelance', confirmButtonText: t('ok')}) :  Swal.fire({icon: "success", text: 'لقد علقت بالفعل على هذا المستقل',confirmButtonText: t('ok')})

        } 
         
    };


    const maxLenghtTextareaCommentaire = (e) => {
            setMaxLenghtTextarea('tebe9a :')
    }

    const maxlenght = 500;
    

    return ( 
        <Paper sx={{p:2, textAlign :'center' }} elevation={0}>
            <form onSubmit={commenter}>
             <Grid container spacing={2} >
                <Grid item sm={12}>
                    <Typography sx={{fontWeight:'900', fontSize:'1rem', textAlign:'center'}}>{t('descr_commentaire')}</Typography>
                    <Rating sx={{direction:'initial'}} name="half-rating" value={valueRatings} onChange={(event) => {setValueRatings(event.target.value)}}  />
                </Grid>
                <Grid item sm={6}>
                    <TextField required id="outlined-basic" label={t('nom')} value={nom} onChange={(event) => {setNom(event.target.value)}} variant="outlined" sx={{width:'100%'}}/>
                </Grid>
                <Grid item sm={6}>
                    <TextField required id="outlined-basic" label={t('email')} value={email} onChange={(event) => {setEmail(event.target.value)}} variant="outlined" sx={{width:'100%'}}/>
                </Grid>
                <Grid item sm={12} sx={{width: '100%'}}>
                <TextareaAutosize
                    required
                    maxRows={40}
                    aria-label="maximum height"
                    placeholder={t('descr_commentaire')} 
                    style={{ width: '100%', height : 100 }} 
                    maxLength={maxlenght}
                    value={commentaire} 
                    onChange={(event) => {setCommentaire(event.target.value)
                     setMaxLenghtTextarea( t('msg_max_length_textarea_commentaire') + ' : ' +( maxlenght - event.target.value.length))
                    }}
                    />
                    <Typography sx={{color:'#d72412', textAlign:'start'}}>{maxLenghtTextarea}</Typography>  
                </Grid> 
                <Grid item sm={12} sx={{textAlign:'center'}}>
                    <Button type='submit' variant="contained">{t('envoyer')}</Button>
                </Grid> 
            </Grid> 
            </form>
        </Paper>
    );
}

export default Freelance_form_commentaire;
