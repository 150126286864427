import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper'; 
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button'; 
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Tooltip from '@mui/material/Tooltip'; 
import api from '../services/api';
import {useNavigate} from 'react-router-dom'; 
import Swal from 'sweetalert2';
import { useParams,} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function Avis_edit() {
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState(0);  
  const navigate = useNavigate();
  const {id} = useParams();
  // form avis 
  const [societe, setSociete] = useState({});
  const [avis, setAvis] = useState({}); 
  const [skills, setSkills] = useState([]);
  const [idSociete, setIdSociete] = useState();
  const [idjob, setIdJob] = useState();
  const [nom_fr, setnom_fr] = useState('');
  const [nom_ar, setnom_ar] = useState('');
  const [type, setType] = useState(''); 
  const [telephone, settelephone] = useState('');
  const [email, setemail] = useState(''); 
  const [titre_fr, settitre_fr] = useState('');
  const [titre_ar, settitre_ar] = useState('');
  const [description_fr, setdescription_fr] = useState('');
  const [description_ar, setdescription_ar] = useState('');
  const [domaine, setDomaine] = useState('');
  const [taille, setTaille] = useState(''); 
  const [periode, setPeriode] = useState(''); 
  const [experience, setExperience] = useState(''); 
  const [budget_min, setbudget_min] = useState('');
  const [budget_max, setbudget_max] = useState('');
  const [budget_discute, setbudget_discute] = useState(1);
  const [slug, setSlug] = useState('');

  const [nom_skill_fr, setnom_skill_fr] = useState([]);
  const [nom_skill_ar, setnom_skill_ar] = useState([]);  

  const [checked, setChecked] = useState(false);
  
  const [list, setList] = useState([]);
   
  useEffect(() => { 
      handleGetavis();  
  },[]);


  const ajouterAuterFichier = () => { 
    setList([...list, list])
  };

  const removeFichier = (value, index) => {  
      var arr = list.filter(item => item !== value)  
      setList(arr) 
  }



  
  const handleChange = (event,newValue) => {
    setValue(newValue);  
  };


  const handleGetavis = () => {   
    api.get(`/api/edite_Avis/${id}`, {
        headers: {
        "Accept": "application/json", 
        }
    }).then(response => {   
        setSociete(response.data.s) 
        setIdSociete(response.data.s.id) 
        setnom_fr(response.data.s.nom_fr) 
        setnom_ar(response.data.s.nom_ar) 
        setType(response.data.s.type)  
        settelephone(response.data.s.telephone) 
        setemail(response.data.s.email)   

        setAvis(response.data.job) 
        setIdJob(response.data.job.id) 
        settitre_fr(response.data.job.titre_fr) 
        settitre_ar(response.data.job.titre_ar) 
        setdescription_fr(response.data.job.description_fr) 
        setdescription_ar(response.data.job.description_ar) 
        setDomaine(response.data.job.domaine)    
        setTaille(response.data.job.taille)  
        setPeriode(response.data.job.periode)  
        setExperience(response.data.job.experience)  
        setbudget_min(response.data.job.budget_min) 
        setbudget_max(response.data.job.budget_max) 
        setbudget_discute(response.data.job.budget_discute)

        if(i18n.language == 'fr'){ 
          setSlug(response.data.job.slug_fr) 
        }else{ 
          setSlug(response.data.job.slug_ar) 
        }

        setSkills(response.data.skills) 
        
      
             
        if (response.data.job.budget_discute == 1) {
          setChecked(true) 
        }
        else{
          setChecked(false) 
        }             
    }).catch(error => { 
        console.log('error'); 
    });
         
  }; 



  const modifierAvis = (e) => { 
    e.preventDefault(); 

    Swal.fire ({
      title: t('attender'),
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading ()
      }
    })
    

    const form = new FormData(); 
    form.append('id', idSociete);
    form.append('idjob', idjob);
    form.append('nom_fr', nom_fr);
    form.append('nom_ar', nom_ar);
    form.append('type', type);
    form.append('telephone', telephone);
    form.append('email', email);
    form.append('titre_fr', titre_fr);
    form.append('titre_ar', titre_ar);
    form.append('description_fr', description_fr);
    form.append('description_ar', description_ar);
    form.append('domaine', domaine);
    form.append('taille', taille);
    form.append('experience', experience);
    form.append('periode', periode);
    form.append('budget_min', budget_min);
    form.append('budget_max', budget_max);
    form.append('budget_discute', budget_discute);

    nom_skill_fr.forEach(element => {
        form.append('nom_skill_fr[]', element); 
    });

    nom_skill_ar.forEach(element => {
        form.append('nom_skill_ar[]', element); 
    }); 
     

    api.post('/api/modifier_Avis', form, {
        headers: {
            'Accept': 'application/json', 
        }
    }).then(response => {  
      console.log(response.data)
        Swal.fire({icon: "success", text: t('edite_avis'),confirmButtonText: t('ok')})
        
        navigate(`/avis/${slug}/${id}`);
    }).catch(error => {
      console.log('error');
    });
  };

  const chexbox = event => {
    if (event.target.checked) { 
      setbudget_discute(1);
      setChecked(true)
      setbudget_max(0);
      setbudget_min(0)
      
    } else {
      setbudget_discute(0); 
      setChecked(false) 
       
    }
  };


  const deleteCompetance = (idcomp) => {   
        api.get(`/api/deleteCompetance/${idcomp}`, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {   
            setSkills(response.data.competance2)
        }).catch(error => {
            console.log('error'); 
        });
         
    }; 

  return (
   <Container sx={{mt :3}}>
       
      <Paper align="center" sx={{p: 2, mb :2}}>
          <Typography sx = {{fontWeight: '900', fontSize: 20,}}>
              {t('modif_avis')}
          </Typography>
      </Paper>
     

      <Box sx={{flexGrow: 1, bgcolor: 'background.paper', display: {sm :'flex'} }} >
           
           {/* affichage tab for pc */}
            <Tabs
              orientation='vertical'  
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example" 
              sx={{ borderRight: 1, borderColor: 'divider', display: { xs: 'none', md: 'flex' }, minWidth :250  }} >
              <Tab label={t('info_Soc')}  className="tabInfoSoc" />
              <Tab label={t('travail')}  className='tabAddtravail'/>
              <Tab label={t('comptences')} className='tabComptance'/> 
            </Tabs> 
             
             {/* affichage tab for mobile */}
            <Tabs    
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              align="center"
              sx={{ borderRight: 1, borderColor: 'divider', textAlign : 'left', display: { xs: 'block', sm: 'none' }  }} >
              <Tooltip title={t('info_Soc')}><Tab icon={<PersonPinIcon />} aria-label="phone" /></Tooltip>
              <Tooltip title={t('travail')}><Tab icon={<BusinessCenterIcon />} aria-label="favorite" /></Tooltip>
              <Tooltip title={t('comptences')}><Tab icon={<EngineeringIcon />} aria-label="person"/></Tooltip>
            </Tabs>

            <form onSubmit={modifierAvis}>
              <TabPanel value={value} index={0}  >
                  <Typography sx={{fontWeight : 'bold', mb : 3}}>
                      {t('info_Soc')}
                  </Typography> 
                  <Grid container spacing={2} >
                    <Grid item md={6} sx={{width : '100%'}}>
                      <TextField id="outlined-uncontrolled" 
                      label="Nom"
                      required
                      variant="outlined"
                      sx={{width : '100%'}}
                      value={nom_fr}
                      onChange={(event) => {setnom_fr(event.target.value)}}
                    />
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                      <TextField id="outlined-uncontrolled" 
                      label="الاسم"
                      required
                      variant="outlined"
                      sx={{width : '100%'}}
                      value={nom_ar}
                      onChange={(event) => {setnom_ar(event.target.value)}}
                    />
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{t('type')}</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={t('type')}
                          value={type}
                          onChange={
                          (event) => {setType(event.target.value)}}
                        >
                          <MenuItem value='personnel}'>{t('personnel_ar')}</MenuItem>
                          <MenuItem value='societe'>{t('societe_ar')}</MenuItem>
                          <MenuItem value='ONG'>{t('ONG_ar')}</MenuItem>
                          <MenuItem value='auters'>{t('auters_ar')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>


                    <Grid item md={6} sx={{width : '100%'}}>
                      <TextField id="outlined-uncontrolled" 
                        label="Email"
                        variant="outlined"
                        sx={{width : '100%'}}
                        value={email}
                        onChange={(event) => {setemail(event.target.value)}}
                      />
                    </Grid>

                    <Grid item md={6} sx={{width : '100%'}}>
                    <TextField id="outlined-uncontrolled" 
                        label="Telephone"
                        required
                        variant="outlined"
                        sx={{width : '100%'}}
                        value={telephone}
                        onChange={(event) => {settelephone(event.target.value)}}
                      />
                    </Grid>

              
                    <Grid item md={12} sx={{width : '100%'}}> 
                        <Button className='btn' variant="contained" onClick={()=> setValue(1)} sx={{float : 'left'}}>{t('suivant')}</Button>
                    
                    </Grid>
                  </Grid> 
              </TabPanel>

              <TabPanel value={value} index={1}>
                  <Typography sx={{fontWeight : 'bold', mb : 3}}>
                      {t('travail')}
                  </Typography>        
                  <Grid container spacing={2} >
                    <Grid item md={12} sx={{width : '100%'}}>
                      <TextField id="outlined-uncontrolled" 
                        required
                        label="Titre de la mission "
                        variant="outlined"
                        sx={{width : '100%'}}
                        value={titre_fr}
                        onChange={(event) => {settitre_fr(event.target.value)}}
                      />
                    </Grid>

                    <Grid item md={12} sx={{width : '100%'}}>
                      <TextField id="outlined-uncontrolled" 
                        required
                        label="العنوان "
                        variant="outlined"
                        sx={{width : '100%'}}
                        value={titre_ar}
                        onChange={(event) => {settitre_ar(event.target.value)}}
                      />
                    </Grid>


                    <Grid item md={6} sx={{width : '100%'}}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">المجال</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="المجال" 
                          value={domaine}
                          onChange={(event) => {setDomaine(event.target.value)}}
                        >
                          <MenuItem value='Services_commerciaux'>{t('Services_commerciaux')}</MenuItem>
                          <MenuItem value='Développement_web'>{t('Développement_web')}</MenuItem>
                          <MenuItem value='Design'>{t('Design')}</MenuItem>
                          <MenuItem value='Ventes'>{t('Ventes')}</MenuItem>
                          <MenuItem value='Redaction'>{t('Redaction')}</MenuItem>
                          <MenuItem value='Services_comptables'>{t('Services_comptables')}</MenuItem>
                          <MenuItem value='Formation'>{t('Formation')}</MenuItem>
                          <MenuItem value='Architecture'>{t('Architecture')}</MenuItem>
                          <MenuItem value='Autres'>{t('Autres')}</MenuItem> 


                        
                        </Select>
                      </FormControl>
                    </Grid>


                    <Grid item md={6} sx={{width : '100%'}}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">الحجم</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="الحجم" 
                          value={taille}
                          onChange={(event) => {setTaille(event.target.value)}}
                        >
                          <MenuItem value='grand'>{t('grand')}</MenuItem>
                          <MenuItem value='moyenne'>{t('moyenne')}</MenuItem>
                          <MenuItem value='petit'>{t('petit')}</MenuItem>

                        </Select>
                      </FormControl>
                    </Grid>


                    <Grid item md={6} sx={{width : '100%'}}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">الفترة {t('p1')}</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="الفترة" 
                          value={periode}
                          onChange={(event) => {setPeriode(event.target.value)}}
                        >
                          <MenuItem value='p1'>{t('p1')}</MenuItem>
                          <MenuItem value='p2'>{t('p2')}</MenuItem>
                          <MenuItem value='p3'>{t('p3')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                  

                    <Grid item md={6} sx={{width : '100%'}}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">الخبرة</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="الخبرة" 
                          value={experience}
                          onChange={(event) => {setExperience(event.target.value)}}
                        >
                          <MenuItem value='debutant'>{t('debutant')}</MenuItem>
                          <MenuItem value='intermediaire'>{t('intermediaire')}</MenuItem>
                          <MenuItem value='expere'>{t('expere')}</MenuItem>



                        </Select>
                      </FormControl>
                    </Grid>


                    <Grid item md={12} sx={{width : '100%'}}>
                      <Typography sx={{fontWeight : '900'}}>
                        {t('budget')}
                      
                      </Typography>
                      <Typography sx={{ fontSize : 13}}> 
                      {t('message_titre_budget')}
                      </Typography>
                    </Grid>

                    <Grid item md={4} sx={{width : '100%'}}>
                    <TextField id="outlined-uncontrolled" 
                        label={t('budget_Min')}
                        variant="outlined"
                        sx={{width : '100%'}}
                        value={budget_min}
                        onChange={(event) => {setbudget_min(event.target.value)}}
                      />
                    </Grid>

                    <Grid item md={4} sx={{width : '100%'}}>
                    <TextField id="outlined-uncontrolled" 
                        label={t('budget_Max')}
                        variant="outlined"
                        sx={{width : '100%'}}
                        value={budget_max}
                        onChange={(event) => {setbudget_max(event.target.value)}}
                      />
                    </Grid>

                    <Grid item md={4} sx={{width : '100%'}}> 
                  
                    <FormControlLabel  
                        control={<Checkbox checked={checked} onChange={chexbox} />}
                        label={t('discute')}
                        labelPlacement="top"
                        sx={{width : '100%'}} 
                        
                      /> 
                    </Grid>

                    <Grid item md={12} sx={{width : '100%'}}>
                    <TextareaAutosize
                        required
                        maxRows={40}
                        aria-label="maximum height"
                        placeholder="Entrez une description détaillée de votre projet si possible." 
                        style={{ width: '100%', height : 100 }}
                        value={description_fr}
                        onChange={(event) => {setdescription_fr(event.target.value)}}
                      />  
                    </Grid>

                    <Grid item md={12} sx={{width : '100%'}}>
                    <TextareaAutosize
                        required
                        maxRows={40}
                        aria-label="maximum height"
                        placeholder="أدخل وصفًا تفصيليًا لمشروعك إن أمكن." 
                        style={{ width: '100%', height : 100 }}
                        value={description_ar}
                        onChange={(event) => {setdescription_ar(event.target.value)}}
                      />  
                    </Grid>

                    <Grid item md={12} sx={{width : '100%'}}> 
                      <Button className='btn' variant="contained" onClick={()=> setValue(0)} sx={{float : 'right'}}>{t('precedant')}</Button>
                      <Button className='btn' variant="contained" onClick={()=> setValue(2)} sx={{float : 'left'}}>{t('suivant')}</Button>
                     
                    </Grid>
                  </Grid> 
              </TabPanel> 

              <TabPanel value={value} index={2}>
                <Typography sx={{fontWeight : 'bold', mb : 3}}>
                    {t('comptences')}
                </Typography> 

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650, background : '#eceef0', mt : 1 }} aria-label="simple table">
                      <TableHead>
                        <TableRow> 
                            <TableCell align="right" sx={{fontWeight:'bold'}}>Competances</TableCell>
                            <TableCell align="right" sx={{fontWeight:'bold'}}>المهارات</TableCell> 
                            <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {skills?.map((row, index) => (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          
                          <TableCell align="right">{row.nom_fr}</TableCell>
                          <TableCell align="right">{row.nom_ar}</TableCell> 
                          <TableCell align="right">
                            <Button onClick={(id) => deleteCompetance(row.id)} sx={{color:'red'}}> <DeleteIcon /> </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                      </TableBody>
                  </Table>
                </TableContainer>

 
                


              <Grid container spacing={2}> 
                  <Grid item xs={12}>
                    <table style={{width:'100%', marginTop:'7px'}}>
                      <tbody>
                        {list.length >0 ?
                            list.map((item, index) => {
                              return <tr key={item} > 
                                
                                  <td style={{width:'80%'}}>
                                    <Grid container spacing={2}> 
                                      <Grid item xs={6}>
                                        
                                        <TextField id="outlined-uncontrolled" 
                                            required 
                                            key={index}
                                            label="Compétences"
                                            variant="outlined"
                                            sx={{width : '100%'}}
                                            

                                            onInput={(event) => { 
                                            setnom_skill_fr((prevArr) => {
                                              const result = [...prevArr];
                                              result[index] = event.target.value; 
                                              return result; 
                                            })} }
                                        /> 
                                      </Grid>

                                      <Grid item xs={6}>  
                                          <TextField id="outlined-uncontrolled" 
                                              required
                                              
                                              key={index}
                                              label="الخبرة"
                                              variant="outlined"
                                              sx={{width : '100%'}} 
                                              onInput={(event) => { 
                                              setnom_skill_ar((prevArr) => {
                                                const result = [...prevArr];
                                                result[index] = event.target.value; 
                                                return result; 
                                              })} }
                                          /> 
                                      </Grid>
                                      
                                    </Grid>

                                  </td>
                                    
                                  <td> 
                                      {
                                          list.length!== 0 &&
                                          <Button  className='btn btn-success' style={{background:'none', color:'red', border:'0px',width:'20%'}} onClick={() =>removeFichier(item,index)}>X</Button>
                                      }
                                      {/* { 
                                          list.length-1==index &&
                                          <Button className='btn' variant="contained" sx={{mt:3}} onClick={ajouterAuterFichier}> {t('autre_fichier')}</Button>
                                      } */}
                                  </td>    
                              </tr>
                          })
                          : null}
                      </tbody>
                  </table>
                  </Grid>

                  <Grid item xs={12}> 
                      <Button className='btn' variant="contained" sx={{mt:3, float:'inline-end', m:'3px 0px 10px 0px'}} onClick={ajouterAuterFichier}> {t('auter_comprtence')}</Button>
                  </Grid>
              </Grid>





                <Grid container spacing={2}>

                  <Grid item md={12} sx={{width : '100%'}}> 
                    <Button className='btn' onClick={()=> setValue(1)} variant="contained" sx={{float : ' right'}}>{t('precedant')}</Button>
                    <Button className='btn' variant="contained" sx={{float : 'left'}} type='submit'>{t('envoyer')}</Button> 
                  
                  </Grid>
                </Grid> 

              </TabPanel>
            </form> 
      </Box>
     
    </Container>
  );
}
