import React, {useEffect} from 'react';
import {AppBar, Box, Toolbar, IconButton, Typography, Menu, Button, Tooltip, Avatar} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu'; 
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add'; 
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LoginIcon from '@mui/icons-material/Login';
import Person3Icon from '@mui/icons-material/Person3';
import LogoutIcon from '@mui/icons-material/Logout'; 
import TranslateIcon from '@mui/icons-material/Translate';
import Groups2Icon from '@mui/icons-material/Groups2';
import LanguageIcon from '@mui/icons-material/Language';

import api from '../services/api';  
import {useNavigate, Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t, i18n } = useTranslation(); 

  useEffect(()=>{
    selectCSS();
  },[]);

  const selectCSS = () => {
    if (i18n.language == 'fr'){
      import('../css/css_fr.css');
    } else if (i18n.language == 'ar'){
      import('../css/css_ar.css');
    }
  }

  
  

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElLangue, setAnchorElLangue] = React.useState(null);
  //window.localStorage.removeItem('userData');
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const navigate = useNavigate(); 
  let code = 0;
  if(userData !=null ){ 
    let code = userData.code;
  } 


  const handleLogout = (e) => {
    e.preventDefault();
    api.post('/api/logout', {
      headers: {
        "Accept": "application/json",
        "Authorization": 'Bearer ' + userData.token
      }
    }).then(response => {
      window.localStorage.removeItem('userData');
      navigate('/');
      setAnchorElUser(null);
    }).catch(error => {
      console.log(error);
    });
  };


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenLangueMenu = (event: React.MouseEvent<HTMLElement>) => {
    console.log('langue')
    setAnchorElLangue(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseLangueMenu = () => {
    setAnchorElLangue(null);
  };
const url = window.location.pathname;
const url2 = window.location.href;
  
    

  return ( 

    <AppBar position="static" className='navbar'>  
        <Toolbar>

            {/* logo in pc */}
            <Typography component={Link} to="/" sx={{   display: { xs: 'none', md: 'flex' }, }}> 
                <img src ="/logo.png" alt = "logo" height="55" /> 
            </Typography>
            {/* /logo in pc */}

            {/* debut menu1 mobile */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none', margin : 7},
                }} >
                  <Button component={Link} to="/" onClick={handleCloseNavMenu} className='btn-in-nav-phone' >
                   <HomeIcon className='inline-icon-in-navbar'/> {t('accueil')} 
                </Button>
                <Button component={Link} to={`/publier/${code}`} onClick={handleCloseNavMenu} className='btn-in-nav-phone' >
                    <AddIcon className='inline-icon-in-navbar'/>{t('publiez_votre_annonce')}
                </Button>
                <Button component={Link} to="/freelances" onClick={handleCloseNavMenu}  className='btn-in-nav-phone'>
                    <Groups2Icon className='inline-icon-in-navbar'/> {t('freelancers')} 
                </Button>
                
              </Menu>

            </Box>
            {/* fin menu1 mobile */}

            {/* logo in mobile */}
            <Typography  component={Link} to="/" sx={{  display: { xs: 'flex', md: 'none' }, flexGrow: 1, }}> 
              <img src ="/logo.png" alt = "logo" height="55" />
            </Typography> 
            {/* /logo in mobile */}


            {/* debut nav pc */} 
            <Box sx={{ flexGrow: 1, mr:2,  display: { xs: 'none', md: 'flex' } }}>
                <Button component={Link} to="/" className={url == '/' ? 'btn-in-nav-bar active' : 'btn-in-nav-bar'} >
                    <HomeIcon className='inline-icon-in-navbar'/> {t('accueil')} 
                </Button>
                <Button  className={(url2.startsWith('https://link.mr/publier')) ? 'btn-in-nav-bar active' : 'btn-in-nav-bar'} component={Link} to={`/publier/${code}`}  >
                    <AddIcon className='inline-icon-in-navbar' sx={{fontSize : 50}}/> {t('publiez_votre_annonce')} 
                </Button>
                <Button className={(url == '/freelances') || (url2.startsWith('https://link.mr/detail_freelance')) || (url2.startsWith('https://link.mr/projet')) || (url2.startsWith('https://link.mr/editeProfile')) || (url2.startsWith('https://link.mr/projet')) ? 'btn-in-nav-bar active' : 'btn-in-nav-bar'} component={Link} to="/freelances">
                    <Groups2Icon className='inline-icon-in-navbar'/> {t('freelancers')} 
                </Button>
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}> 
                { userData != null ?
                  userData.user.type != 1 ?
                  <Button component={Link} to="/profil" className={(url == '/profil') || (url == '/rim') || (url == '/ajouterProject')  || (url2.startsWith('https://link.mr/modifierProject'))  ? 'btn-in-nav-bar active' : 'btn-in-nav-bar'} >
                    <Person3Icon className='inline-icon-in-navbar'/>{t('profil')}
                </Button> : 
                <Button component={Link} to="/sociteProfile" className={(url == '/profil') || (url == '/rim') || (url == '/ajouterProject')  || (url2.startsWith('https://link.mr/modifierProject'))  ? 'btn-in-nav-bar active' : 'btn-in-nav-bar'} >
                    <Person3Icon className='inline-icon-in-navbar'/>{t('profil')}
                </Button>
                
                :
                              
                <Button component={Link} to="/inscrire" className={url == '/inscrire' ? 'btn-in-nav-bar active' : 'btn-in-nav-bar'} >
                    <PersonAddIcon className='inline-icon-in-navbar'/> {t('inscrit')} 
                </Button>
                }

                { userData != null ?  
                <Button component={Button} onClick={handleLogout}  className='btn-in-nav-bar'>
                   <LogoutIcon className='inline-icon-in-navbar'/> {t('logout')}
                </Button> :
                <Button component={Link} to="/login" className={url == '/login' ? 'btn-in-nav-bar active' : 'btn-in-nav-bar'} >
                   <LoginIcon className='inline-icon-in-navbar'/> {t('log-in')}
                </Button>
                }
                {i18n.language == 'fr' ?
                <Button onClick={() => { i18n.changeLanguage('ar'); window.location.reload()  }} sx={{color: 'white', display: 'block',  fontWeight: 700, fontSize : 16, fontFamily : 'Almarai' }} >
                 <TranslateIcon className='inline-icon-in-navbar'/> العربية
                </Button>
                :
                <Button onClick={() => { i18n.changeLanguage('fr'); window.location.reload() }} sx={{ color: 'white', display: 'block',  fontWeight: 700, fontFamily : 'monospace', textTransform:'initial' }} >
                  <TranslateIcon className='inline-icon-in-navbar'/> Francais
                </Button>
                } 
            </Box>
            {/* fin nav pc */}


            {/* debut menu2 mobile */}
            <>
              {i18n.language == 'fr' ?
              <Box sx={{ flexGrow: 0, display: { xs: 'block', sm: 'none' } }}>
                <Tooltip >
                  <IconButton onClick={handleOpenLangueMenu} sx={{ p: 0 }}>
                    <LanguageIcon sx={{color:'#fff', fontSize:40, mr:1, ml:1 }}/>
                    
                  </IconButton>
                </Tooltip>
                <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElLangue}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElLangue)}
                  onClose={handleCloseLangueMenu} > 
                  <Button onClick={() => { i18n.changeLanguage('ar'); setAnchorElUser(null); window.location.reload() }} sx={{   display: 'block',  fontWeight: 700, fontSize : 15, fontFamily : 'Almarai'}} >
                      العربية
                      <img src="/img/rim.png" alt="drap fr" style={{width:'20px', marginLeft:'5px'}} />
                  </Button>
                  
                
                </Menu>
              </Box>
              :
              <Box sx={{ flexGrow: 0, display: { xs: 'block', sm: 'none' } }}>
                <Tooltip >
                  <IconButton onClick={handleOpenLangueMenu} sx={{ p: 0 }}> 
                    <LanguageIcon sx={{color:'#fff', fontSize:40, mr:1, ml:1 }}/>
                  </IconButton>
                </Tooltip>
                <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElLangue}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElLangue)}
                  onClose={handleCloseLangueMenu} > 

                  
                  <Button onClick={() => { i18n.changeLanguage('fr'); setAnchorElUser(null); window.location.reload()}} sx={{  display: 'inherit',  fontWeight: 700, fontFamily : 'monospace' ,textTransform:'initial' }} >
                    Francais
                    <img src="/img/fr.png" alt="drap fr" style={{width:'20px', marginRight:'5px'}} />
                  </Button>
                  
                
                </Menu>
              </Box>
              }
              <Box sx={{ flexGrow: 0, display: { xs: 'block', sm: 'none' } }}>
                <Tooltip >
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="/img/undraw_Pic_profile.png" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu} >
                
                  { userData != null ? 
                  userData.user.type != 1 ? 
                  <Button component={Link} to="/profil" onClick={handleCloseUserMenu}  className='btn-in-nav-phone' >
                        <Person3Icon className='inline-icon-in-navbar'/>{t('profil')}
                  </Button> : 
                  <Button component={Link} to="/sociteProfile" onClick={handleCloseUserMenu}  className='btn-in-nav-phone' >
                        <Person3Icon className='inline-icon-in-navbar'/>{t('profil')}
                  </Button>
                    :    
                  <Button component={Link} to="/inscrire" onClick={handleCloseUserMenu}  className='btn-in-nav-phone'>
                      <PersonAddIcon className='inline-icon-in-navbar'/> {t('inscrit')} 
                  </Button>   
                  } 
                  
                  { userData != null ?
                  <Button component={Button} onClick={handleLogout} className='btn-in-nav-phone'>
                    <LogoutIcon className='inline-icon-in-navbar'/> {t('logout')} 
                  </Button>
                  
                  :
                  <Button component={Link} to="/login" onClick={handleCloseUserMenu} className='btn-in-nav-phone'>
                      <LoginIcon className='inline-icon-in-navbar'/> {t('log-in')}
                  </Button> 
                  } 
                </Menu>
              </Box>
  

              
            </>
            {/* fin menu2 mobile */}

              
        </Toolbar>
       
    </AppBar> 
 
  );
}
export default Navbar;

