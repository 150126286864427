import React from 'react'; 
import { Grid, Typography, Paper, MenuItem, FormControl, Select, InputLabel, TextareaAutosize, Button, TextField } from '@mui/material';  
import { useTranslation } from 'react-i18next'; 
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const Freelance_form_resum = (props) => {
    const { t, i18n } = useTranslation();

    function handleOnChange(value) { 
      props.setTelephone(value);
    }

    return ( 
        <Grid container spacing={1} >
            <Grid item md={3} sx={{width : '100%'}}> 
                <Paper sx={{p: 2,mt:2, textAlign : 'center'}} elevation={0}>
                    {props.photo == null ? <img className='image' src='../img/emptyprofile.png' height='140' width='140' alt='profil'/> : 
                    <img className='image' src={'https://link.mr/back/storage/images/' + props.photo} alt='Profile' width="140" height="140" sx={{ width: 56, height: 56, borderRadius: '50%'}} />
                    }

                    <Typography variant="body2" sx={{ mt : 0.5, mb :1 }}>[png,jpeg,jpg,gif]</Typography>
                    <input type="file" accept="image/*" onChange={props.setImage}/>   
                </Paper> 
            </Grid>

            <Grid item md={9} sx={{width : '100%'}}>   
                <Paper sx={{p: 2, mt:2}}>   
                    <Grid container spacing={2} >
                        <Grid item md={6} sx={{width : '100%'}}>
                            <TextField id="outlined-uncontrolled" 
                                required
                                label={t('nom')}
                                variant="outlined"
                                sx={{width : '100%'}} 
                                value={props.nom}
                                onChange={props.setNom}
                            />
                        </Grid>

                        <Grid item md={6} sx={{width : '100%'}}>
                            {/* <TextField id="outlined-uncontrolled"
                                required 
                                label={t('phone')}
                                variant="outlined"
                                sx={{width : '100%'}}
                                value={props.telephone}
                                onChange={props.setTelephone} 
                            /> */}
                                    
                            <PhoneInput country='mr' variant="outlined" placeholder='' value={props.telephone}
                                preferredCountries={['mr', 'ma', 'dz', 'tn','ly', 'sa', 'jo', 'qa', 'ae', 'kw', 'iq', 'om', 'ps', 'sd']}
                                specialLabel={t('phone')}
                                onChange={phone => props.setTelephone(phone)}
                                style={{width:'100%'}} />
                        </Grid>

                        <Grid item md={6} sx={{width : '100%'}}>
                            <TextField id="outlined-uncontrolled" 
                                required
                                label={t('email')}
                                variant="outlined"
                                sx={{width : '100%'}} 
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={props.email}
                                onChange={props.setEmail} 
                            />
                        </Grid>

                        <Grid item md={6} sx={{width : '100%'}}>
                            <TextField id="outlined-uncontrolled"  
                                label={t('nni')}
                                variant="outlined"
                                sx={{width : '100%'}} 
                                value={props.nni}
                                onChange={props.setNni} 
                            />
                        </Grid>

                        
                        <Grid item md={12} sx={{width : '100%'}}>
                            <FormControl fullWidth> 
                                <InputLabel id="demo-simple-select-label">{t('domaine')}</InputLabel>
                                <Select
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t('domaine')} 
                                value={props.domaine}
                                onChange={props.setDomaine} 
                                >
                                <MenuItem value='Services_commerciaux'>{t('Services_commerciaux')}</MenuItem>
                                <MenuItem value='Développement_web'>{t('Développement_web')}</MenuItem>
                                <MenuItem value='Design'>{t('Design')}</MenuItem>
                                <MenuItem value='Ventes'>{t('Ventes')}</MenuItem>
                                <MenuItem value='Redaction'>{t('Redaction')}</MenuItem>
                                <MenuItem value='Services_comptables'>{t('Services_comptables')}</MenuItem>
                                <MenuItem value='Formation'>{t('Formation')}</MenuItem>
                                <MenuItem value='Architecture'>{t('Architecture')}</MenuItem>
                                <MenuItem value='Autres'>{t('Autres')}</MenuItem> 
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={4} sx={{width : '100%'}}>
                            <TextField id="outlined-uncontrolled" 
                                label={t('facebook')}
                                variant="outlined"
                                sx={{width : '100%'}} 
                                value={props.facebook}
                            onChange={props.setFacebook}
                            />
                            <Typography variant="body2" sx={{fontSize : 13, mt : 0.5, color : '#d72412'}}>{t('message_apropos_facebook')}</Typography>
                        </Grid>

                        <Grid item md={4} sx={{width : '100%'}}>
                            <TextField id="outlined-uncontrolled" 
                                label={t('twiter')}
                                variant="outlined"
                                sx={{width : '100%'}} 
                                value={props.twitter}
                            onChange={props.setTwitter}
                            />
                            <Typography variant="body2" sx={{fontSize : 13, mt : 0.5, color : '#d72412'}}>{t('message_apropos_twitter')}</Typography>
                        </Grid>

                        <Grid item md={4} sx={{width : '100%'}}>
                            <TextField id="outlined-uncontrolled" 
                                label={t('youtube')}
                                variant="outlined"
                                sx={{width : '100%'}} 
                                value={props.youtube}
                            onChange={props.setYoutube}
                            />
                            <Typography variant="body2" sx={{fontSize : 13, mt : 0.5, color : '#d72412'}}>{t('message_apropos_youtube')}</Typography>
                        </Grid>

                        <Grid item md={12} sx={{width : '100%'}}>
                            <TextareaAutosize
                                maxRows={40}
                                aria-label="maximum height"
                                placeholder={t('message_apropos')} 
                                style={{ width: '100%', height : 100 }} 
                                value={props.description}
                                onChange={props.setDescription} 
                            />  
                        </Grid>

                        <Grid item md={12} sx={{width : '100%', textAlign : 'center'}}>
                            <Button type='submit'  variant="contained">{t('envoyer')} </Button>
                        </Grid>

                    </Grid>
                </Paper> 
            
            </Grid>
        </Grid>
    );
}

export default Freelance_form_resum;
