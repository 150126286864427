import React, {useEffect, useState} from 'react';
import api from '../services/api';
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Freelance_service_detait() {
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const { t, i18n } = useTranslation();
    const [service, setService] = useState({});
    const [emailFree, setEmailFree] = useState();
    const {id} = useParams();

    useEffect(() => {  
        handleGetDetailProjet();
        i18n.language == 'fr' ? document.title = 'Detait service - ' + t('title')  : document.title = 'تفاصيل الخدمة - ' + t('title')  
    },[]);

  
    const handleGetDetailProjet = () => {   
        api.get(`/api/detailService/${id}`, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {    
            setService(response.data.offres_freelance)
            setEmailFree(response.data.offres_freelance.freelance.email) 
        }).catch(error => {
            console.log(error); 
        });
         
    }; 

     
    return(
        <Container sx={{mt : 3}}>




        <Box sx={{ width: '100%' }}>
            <Stack spacing={2}>
                <Item>
                    <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 1,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#1976d2'}}>
                        {t('details_service')}
                    </Typography>
                </Item>

                <Item>
                     <Typography sx={{mt : 1, textAlign : 'start'}}>
                        <span style={{fontWeight : 'bold'}}>{t('titreP')} :</span> {service.titre}
                    </Typography> 
                </Item>

                <Item>
                    {service.photo == null ? <a href="../img/emptyproject.png" target="_blank"><img className='center' src='../img/emptyproject.png' alt='profil'/></a> : 
                    <a href={'https://link.mr/back/storage/images/' + service.photo} target="_blank">
                        <img className='center' src={'https://link.mr/back/storage/images/'  + service.photo}  alt='img'  />
                    </a> 
                    } 
                </Item>
                

                <Item>
                    <Typography sx={{textAlign:'start', fontWeight:'bold'}}>{t('description')} :</Typography>
                    <Typography sx={{mt : 1, textAlign :'justify'}}>{service.description} </Typography> 
                </Item>
                <Item>
                    <Typography sx={{mt : 1, textAlign : 'start'}}>
                        <span style={{fontWeight : 'bold'}}>{t('prix')} :</span> {service.prix}
                    </Typography>  
                </Item>
            </Stack>
        </Box>




        { (userData != null && userData.signedIn && userData.user.email == emailFree) || (userData != null && userData.user.is_admin == 1) ? 
            <Button variant="contained" className='btn' component={Link} to={`/modifierService/${service.id}`} sx={{ mt : 3,}}>{t('modifierService')}</Button> 
        :
        ''}






            {/* <Box component="form"  noValidate autoComplete="off" sx={{ pt:1, mt: 3 }}>
                <Paper sx={{p: 2}}>
                    <Typography sx={{ bgcolor: '#fff',mt: 1,mb : 1,fontWeight: '900',fontSize: 20, textAlign : 'center', color : '#1976d2'}}>
                        {t('details_service')}
                    </Typography>                         
                </Paper>
            </Box> 
 
            <Paper sx={{p: 2, mt:2}}> 
                {service.photo == null ? <a href="../img/emptyproject.png" target="_blank"><img className='center' src='../img/emptyproject.png' alt='profil'/></a> : 
                <a href={'https://link.mr/back/storage/images/' + service.photo} target="_blank">
                    <img className='center' src={'https://link.mr/back/storage/images/'  + service.photo}  alt='img'  />
                </a> 
                }   
                <Typography sx={{mt : 1, textAlign : 'center'}}>
                     <span style={{fontWeight : 'bold'}}>{t('titreP')} :</span> {service.titre}</Typography>
                
                <Typography sx={{mt : 1, textAlign :'justify'}}>{service.description} </Typography> 

                { (userData != null && userData.signedIn && userData.user.email == emailFree) || (userData != null && userData.user.is_admin == 1) ? 
                <Button variant="contained" className='btn' component={Link} to={`/modifierService/${service.id}`} sx={{ mt : 3}}>{t('modifierService')}</Button> 
                :
                ''}     

            </Paper> */}

            
      </Container>
    )


}