import React, {useState, useEffect} from 'react'; 
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';   
import {styled} from '@mui/material/styles';
import GroupIcon from '@mui/icons-material/Group';
import api from '../services/api'; 
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next'; 
import { Pagination } from '@mui/material/';
import Stack from '@mui/material/Stack'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import InputLabel from '@mui/material/InputLabel'; 
import Freelance_resume from '../components/freelance_resume';

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Freelances = () => {
    const { t, i18n } = useTranslation();
    const [freelances, setfreelances] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesNumber, setPagesNumber] = useState(1);
    const [valueRatings, setValueRatings] = useState(0);

    useEffect(() => {  
        handleGetFreelances(1);
        i18n.language == 'fr' ? document.title = 'Freelancers - ' + t('title')  : document.title = 'المستقلين - ' + t('title') 
    }, []);

    const paginate = (event, value) => {
        setPageNumber(value);
        handleGetFreelances(value);
    };
    
 
    const handleGetFreelances = (pn) => {
        api.get('/api/freelancers?page='+pn, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => { 
            setPagesNumber(response.data.freelances.last_page)
            setfreelances(response.data.freelances.data); 
            setTimeout(()=>{
        setIsLoading(false)
      }, 2000)
        }).catch(error => {  
        });
         
    };
    
    
    let chercher_freelances_par_domaine = (e) => {
        var domaine = e.target.value;
        api.get(`api/filter_par_domaine/${domaine}`, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {  
             
            setPagesNumber(response.data.freelances.last_page)
            setfreelances(response.data.freelances.data);

            
        }).catch(error => {
            console.log(error)  
        });

    };

    
    return (
    <> 
        <Paper sx={{p: 2, m:3}}>
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}>
                <Grid item sm={8}>
                    <Typography sx={{bgcolor: '#fff', mt: 1, fontWeight: '900', fontSize: 20, width:'100%'}}> <GroupIcon className='inline-icon-in-navbar'/> {t('freelancers')} </Typography>
                </Grid>
                <Grid item sm={4}>
                    <FormControl fullWidth> 
                        <InputLabel  id="demo-simple-select-label">{t('rechercheFree')}</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t('domaine')}  
                            onChange={chercher_freelances_par_domaine} 
                            sx={{width:'100%'}}
                            > 
                            <MenuItem value='Services_commerciaux'>{t('Services_commerciaux')}</MenuItem>
                            <MenuItem value='Développement_web'>{t('Développement_web')}</MenuItem>
                            <MenuItem value='Design'>{t('Design')}</MenuItem>
                            <MenuItem value='Ventes'>{t('Ventes')}</MenuItem>
                            <MenuItem value='Redaction'>{t('Redaction')}</MenuItem>
                            <MenuItem value='Services_comptables'>{t('Services_comptables')}</MenuItem>
                            <MenuItem value='Formation'>{t('Formation')}</MenuItem>
                            <MenuItem value='Architecture'>{t('Architecture')}</MenuItem>
                            <MenuItem value='Autres'>{t('Autres')}</MenuItem> 
                            </Select>
                        </FormControl>
                </Grid>
            </Grid>
                
        </Paper>  

        {isLoading &&
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress sx={{mt : 16}}/>
        </Box>
        } 
        {!isLoading &&  
        <Box sx={{pl: 3, pr: 3, mt: 2, }}>
            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3, }}>
                {freelances.map((row) => (
                <Grid item md={3} sx={{ width :'100%'}}> 
                    <Freelance_resume photo={row.photo} noteProfile={row.note_profile} nom={row.nom} description={row.description} domaine={row.domaine} service={row.offres_freelance} projet={row.portofolio} commentaire_count={row.commentaire_count} view_count={row.status.view_count} commentaire_avg_etoile={row.commentaire_avg_etoile} id={row.id} codeCountry={row.codeCountry} telephone={row.telephone}></Freelance_resume>
                </Grid>
                ))}  
            </Grid>
            <Stack alignItems="center" sx={{p:3}}>
                <Pagination hideNextButton={true} hidePrevButton={true} sx={{ background:'#fff'}} count={pagesNumber} page={pageNumber} onChange={paginate} variant="outlined" color="primary" shape="rounded" />
            </Stack> 
        </Box>
        }
    </>
  );
};

export default Freelances;