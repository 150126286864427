import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper'; 
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button'; 
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PersonPinIcon from '@mui/icons-material/PersonPin'; 
import api from '../services/api';
import {useNavigate, useParams} from 'react-router-dom'; 
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next' 
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function Avis_publier() {
    const { t, i18n } = useTranslation();
    const [valueTabPublier, setValueTabPublier] = React.useState(0);  
    const navigate = useNavigate();
    // form avis 
    const [focusPhone, setFocusPhone] = useState(false);
    const [nom, setNom] = useState('');
    const [type, setType] = useState('');
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState(''); 
    const [titre, setTitre] = useState('');
    const [description, setDescription] = useState('');
    const [domaine, setDomaine] = useState('');
    const [taille, setTaille] = useState('');
    const [periode, setPeriode] = useState('');
    const [experience, setExperience] = useState('');
    const [codeCountry, setCodeCountry] = useState('');
    const [budget_min, setBudget_min] = useState('');
    const [budget_max, setBudget_max] = useState(0);
    const [budget_discute, setBudget_discute] = useState(0);
    const [nom_skill, setNom_skill] = useState([{ 
      first: "",
      last: ""
    }]); 
    const [validationInput, setValidationInput] = useState('');
    const {code} = useParams();

  
    useEffect(() => { 
      if(code == 0){
        navigate('/login')
      }
      getSosiete();
      
      i18n.language == 'fr' ? document.title = 'Publiez votre mission - ' + t('title') : document.title = 'أضف مهمتك أو مشروعك -' + t('title')           
    },[]);
 
    const handleChangeTabPublier = (event, newValue) => {
     
     if(newValue == 1){ 
       if ((nom.trim().length != 0) && (type.trim().length != 0) && (telephone.trim().length != 0)) {
          setValueTabPublier(newValue);
          setValidationInput('') 
        } else {
          setValidationInput(t('validation_champ'))
        }
     }
     else if(newValue == 2){ 
       if ((titre.trim().length != 0) && (domaine.trim().length != 0) && (taille.trim().length != 0) && (periode.trim().length != 0) && (experience.trim().length != 0) && (description.trim().length != 0) && (((budget_min != 0) && (budget_max != 0)) || (budget_discute != 0) )) {
          setValueTabPublier(newValue);
          setValidationInput('') 
        } else {
          setValidationInput(t('validation_champ'))
        }
     }
     else{
      setValueTabPublier(newValue);
     }
       
    };

    const handleChangeTabPublier1 = (event, newValue) => {
      if ((nom.trim().length != 0) && (type.trim().length != 0) && (telephone.trim().length != 0)) {
        setValueTabPublier(1);
        setValidationInput('') 
      } else {
        setValidationInput(t('validation_champ'))
      }
       
    };

    const handleChangeTabPublier2 = (event, newValue) => {
      if ((titre.trim().length != 0) && (domaine.trim().length != 0) && (taille.trim().length != 0) && (periode.trim().length != 0) && (experience.trim().length != 0) && (description.trim().length != 0) && (((budget_min != '') && (budget_max != 0)) || (budget_discute != 0) )) {
        setValueTabPublier(2);
        setValidationInput('') 
      } else {
        setValidationInput(t('validation_champ'))
      }
       
    };


    let getSosiete = (e) => { 
        api.get(`api/publierAvis/${code}`, {
            headers: {
            "Accept": "application/json", 
            }
        }).then(response => {

          if(response.data != null){  
            const socite = response.data.socite;
            const user = response.data.user;
            const token = response.data.token;
            const code = response.data.socite.code; 
            window.localStorage.setItem('userData', JSON.stringify({signedIn: true, user, token, code, socite}));
            const ud = JSON.parse(window.localStorage.getItem('userData')); 
             
            if(ud != null){ 

              setEmail(ud.socite.email) 
              setNom(ud.user.name) 
              setTelephone(response.data.user.telephone)      
            }
          }

            
        }).catch(error => {
            console.log(error)  
        });
    };

       
    const handleSaveAvis = (e) => {  
      e.preventDefault(); 
      if (nom_skill.length > 0) {
        setValidationInput('') 
        Swal.fire ({
          title: t('attender'),
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          }
        })

        const form = new FormData();
        
        form.append('type', type);
        form.append('domaine', domaine);
        form.append('taille', taille);
        form.append('periode', periode);
        form.append('experience', experience);
        form.append('budget_min', budget_min);
        form.append('budget_max', budget_max);
        form.append('budget_discute', budget_discute);
        form.append('email', email);
        form.append('telephone', telephone); 
        form.append('codeCountry', codeCountry);

        console.log('form avis FF');
        console.log(form);
        
        if(i18n.language == 'fr'){
            form.append('nom_fr', nom); 
            form.append('titre_fr', titre);
            form.append('description_fr', description); 
            form.append('nom_skill_fr', nom_skill);  
        }
        else if(i18n.language == 'ar'){
            form.append('nom_ar', nom); 
            form.append('titre_ar', titre);
            form.append('description_ar', description);
            form.append('nom_skill_ar', nom_skill);  
        }
        else{
          console.log('')
        }
        // return 0;
        api.post('/api/saveAvis', form, {
            headers: {
                'Accept': 'application/json', 
                "Authorization": "*" 
            }
        }).then(response => { 
           console.log(response.data)
          i18n.language == 'fr' ? Swal.fire({icon: "success", text: response.data.messagefr,confirmButtonText: t('ok')}) :  Swal.fire({icon: "success", text: response.data.messageAr,confirmButtonText: t('ok')})
          
          navigate(`/`)
        }).catch(error => {   
        });
        
      } else {
        setValidationInput(t('validation_champ'))
      }
      
    };


  
   
    const [counter, setCounter] = useState(0);
    const ajouterAuterCompetance = () => {
      setCounter(counter + 1); 
    };

    const chexboxBudgetDuscute = event => {
      if (event.target.checked) { 
        setBudget_discute(1);
        setBudget_max(0);
        setBudget_min(0)
      } else {
        setBudget_discute(0);
        
      }
    };

    function handleOnChange(value, country) {  
      setTelephone(value);
      setCodeCountry(country.countryCode);
    }

  return (

   <Container sx={{mt :3}}>
 
      <Paper elevation={0} align="center" sx={{p: 2, mb :2}}>
        <Typography>{t('publiez_votre_annonce_intro')}</Typography>
        <Typography sx = {{fontWeight: '900', fontSize: 25, color : '#1976d2'}}>
            {t('publiez_votre_annonce')}
        </Typography>
      </Paper>
    

      <Box sx={{flexGrow: 1, bgcolor: 'background.paper', display: {sm :'flex'} }} >
        
        {/* affichage tab pour pc */}

        <Tabs 
          orientation='vertical'  
          variant="scrollable"
          value={valueTabPublier}
          onChange={handleChangeTabPublier}  
          sx={{ borderRight: 1, borderColor: 'divider', display: { xs: 'none', md: 'flex' }, minWidth :250  }} >
          <Tab label={t('info_Soc')} className="tab"/>
          <Tab label={t('travail')} className='tab'/>
          <Tab label={t('comptences')}  className='tab'/> 
        </Tabs> 
          

        {/* affichage tab pour mobile */}
        <Tabs    
          className='tabPublier_for_mobile'
          variant="scrollable"
          value={valueTabPublier}
          onChange={handleChangeTabPublier}  
          sx={{ display: { xs: 'block', sm: 'none' }  }} >
          <Tab icon={<PersonPinIcon />} aria-label="phone" />
          <Tab icon={<BusinessCenterIcon />} aria-label="favorite" />
          <Tab icon={<EngineeringIcon />} aria-label="person"/>
        </Tabs>

        <form onSubmit={handleSaveAvis}>
          <TabPanel value={valueTabPublier} index={0}  >
                <Typography sx={{fontWeight : 'bold', mb : 3, fontSize:20}}>
                    {t('info_Soc')} 
                </Typography> 
                <Grid container spacing={2} >

                  <Grid item md={6} sx={{width : '100%'}}>
                    <TextField id="outlined-uncontrolled"
                      required 
                      label={t('nom')}
                      variant="outlined"
                      sx={{width : '100%'}}
                      value={nom}
                      onChange={(event) => {setNom(event.target.value)}}
                  />
                  {nom.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                  <Typography sx={{fontSize:13}}>{t('message_titre_nom')} </Typography>
                  </Grid>

                  <Grid item md={6} sx={{width : '100%'}}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">{t('type')}</InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t('type')}
                        value={type}
                        onChange={
                        (event) => {setType(event.target.value)}}
                      >
                        <MenuItem value='personnel'>{t('personnel')}</MenuItem>
                        <MenuItem value='societe'>{t('societe')}</MenuItem>
                        <MenuItem value='ONG'>{t('ONG')}</MenuItem>
                        <MenuItem value='auters'>{t('auters')}</MenuItem>
                      </Select>
                    </FormControl>
                     {type.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}

                  </Grid>

                  <Grid item md={6} sx={{width : '100%'}}>
                    <TextField id="outlined-uncontrolled" 
                      label={t('email')}
                      variant="outlined"
                      sx={{width : '100%'}}
                      value={email}
                      InputProps={{
                                    readOnly: true,
                                }}
                      onChange={(event) => {setEmail(event.target.value)}}
                    />
                  </Grid>

                  <Grid item md={6} sx={{width : '100%'}}> 
                      <PhoneInput  country='mr' variant="outlined" placeholder='' value={telephone}
                            preferredCountries={['mr', 'ma', 'dz', 'tn','ly', 'sa', 'jo', 'qa', 'ae', 'kw', 'iq', 'om', 'ps', 'sd']}
                            specialLabel={t('phone')}
                            onChange={handleOnChange} style={{width:'100%'}} />


                      {telephone.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}

                    
                  </Grid>


                  <Grid item md={12} sx={{width : '100%'}}> 
                      <Button className='btn btn-left' variant="contained" onClick={handleChangeTabPublier1}>{t('suivant')}</Button>                      
                  </Grid>

                </Grid> 
          </TabPanel>

          <TabPanel value={valueTabPublier} index={1}>
              <Typography sx={{fontWeight : 'bold', mb : 3,fontSize:20}}>
                  {t('travail')}
              </Typography>       
              <Grid container spacing={2} >
                <Grid item md={12} sx={{width : '100%'}}>
                  <TextField id="outlined-uncontrolled"
                    required 
                    label={t('titre')}
                    variant="outlined"
                    sx={{width : '100%'}}
                    value={titre}
                    onChange={(event) => {setTitre(event.target.value)}}
                  />
                  {titre.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                  <Typography sx={{fontSize:13}}>{t('message_titre_prjet')} </Typography>
                </Grid>

                <Grid item md={6} sx={{width : '100%'}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t('domaine')}</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t('domaine')} 
                      value={domaine}
                      onChange={(event) => {setDomaine(event.target.value)}}
                    >
                      <MenuItem value='Services_commerciaux'>{t('Services_commerciaux')}</MenuItem>
                      <MenuItem value='Développement_web'>{t('Développement_web')}</MenuItem>
                      <MenuItem value='Design'>{t('Design')}</MenuItem>
                      <MenuItem value='Ventes'>{t('Ventes')}</MenuItem>
                      <MenuItem value='Redaction'>{t('Redaction')}</MenuItem>
                      <MenuItem value='Services_comptables'>{t('Services_comptables')}</MenuItem>
                      <MenuItem value='Formation'>{t('Formation')}</MenuItem>
                      <MenuItem value='Architecture'>{t('Architecture')}</MenuItem>
                      <MenuItem value='Autres'>{t('Autres')}</MenuItem> 
                    </Select>
                  </FormControl>
                  {domaine.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                </Grid>

                <Grid item md={6} sx={{width : '100%'}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t('taille')} </InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t('taille')}
                      value={taille}
                      onChange={(event) => {setTaille(event.target.value)}}
                    >
                      <MenuItem value='grand'>{t('grand')}</MenuItem>
                      <MenuItem value='moyenne'>{t('moyenne')}</MenuItem>
                      <MenuItem value='petit'>{t('petit')}</MenuItem>
                    </Select>
                  </FormControl>
                  {taille.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                </Grid>

              
                <Grid item md={6} sx={{width : '100%'}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t('periode')}</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t('periode')} 
                      value={periode}
                      onChange={(event) => {setPeriode(event.target.value)}}
                    >
                      <MenuItem value='p1'>{t('p1')}</MenuItem>
                      <MenuItem value='p2'>{t('p2')}</MenuItem>
                      <MenuItem value='p3'>{t('p3')}</MenuItem>
                    </Select>
                  </FormControl>
                    {periode.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                  <Typography sx={{fontSize:13}}>{t('message_titre_periode')} </Typography>
                </Grid>

                <Grid item md={6} sx={{width : '100%'}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t('experience')}</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t('experience')} 
                      value={experience}
                      onChange={(event) => {setExperience(event.target.value)}}
                    >
                      <MenuItem value='debutant'>{t('debutant')}</MenuItem>
                      <MenuItem value='intermediaire'>{t('intermediaire')}</MenuItem>
                      <MenuItem value='expere'>{t('expere')}</MenuItem>
                    </Select>
                  </FormControl>
                    {experience.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>}
                </Grid>


                <Grid item md={12} sx={{width : '100%'}}>
                  <Typography sx={{fontWeight : '900'}}>
                    {t('budget')} 
                  </Typography>
                  <Typography sx={{ fontSize : 13}}> 
                  {t('message_titre_budget')}
                  </Typography>
                </Grid>

                <Grid item md={4} sx={{width : '100%'}}>
                <TextField id="outlined-uncontrolled" 
                    label={t('budget_Min')}
                    variant="outlined"
                    sx={{width : '100%'}}
                    value={budget_min}
                    onChange={(event) => {setBudget_min(event.target.value); setBudget_discute(0)}}
                  />
                </Grid>

                <Grid item md={4} sx={{width : '100%'}}>
                  
                  <TextField id="outlined-uncontrolled" 
                    label={t('budget_Max')}
                    variant="outlined"
                    sx={{width : '100%'}}
                    value={budget_max}
                    onChange={(event) => {setBudget_max(event.target.value); setBudget_discute(0)}}
                  />
                </Grid>
                  


                <Grid item md={4} sx={{width : '100%'}}> 
                <FormControlLabel  
                    control={<Checkbox checked={budget_discute == 1 ? true : false}  onChange={chexboxBudgetDuscute}/>}
                    label={t('discute')}
                    labelPlacement="top"
                    sx={{width : '100%'}} 
                    value={budget_discute} 
                  /> 
                </Grid>

                <Grid item md={12} sx={{width : '100%'}}> 
                  {(budget_min != '' && budget_max !== 0) || budget_discute !== 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>} 
                </Grid>

                <Grid item md={12} sx={{width : '100%'}}>
                <TextareaAutosize
                    required
                    maxRows={40}
                    aria-label="maximum height"
                    placeholder={t('message_titre_desciption')} 
                    style={{ width: '100%', height : 100 }}
                    value={description}
                    onChange={(event) => {setDescription(event.target.value)}}
                  /> 
                  {description.trim().length != 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>} 
                </Grid>

                <Grid item md={12} sx={{width : '100%'}}> 
                  <Button className='btn btn-right' variant="contained" onClick={()=> setValueTabPublier(0)} sx={{float : 'left'}}>{t('precedant')}</Button>
                  <Button className='btn btn-left' variant="contained" onClick={handleChangeTabPublier2} sx={{float : 'right'}}>{t('suivant')}</Button>

                </Grid>
              </Grid> 
          </TabPanel>

          <TabPanel value={valueTabPublier} index={2}>
              <Typography sx={{fontWeight : 'bold', mb : 3,fontSize:20}}>
                    {t('comptences')}
              </Typography> 
              <Grid container spacing={2}>


                <Grid item xs={6}>
                <TextField id="outlined-uncontrolled"
                    required 
                    label={t('competence')}
                    variant="outlined"
                    sx={{width : '100%'}} 
                    value={nom_skill.first}
                    onInput={(event) => {  setNom_skill([event.target.value])} }

                  />
                  {nom_skill.length > 0 ? '' : <Typography sx={{fontSize:10, color:'red'}}>{validationInput}</Typography>} 
                  <Typography sx={{fontSize:12}}>{t('message_titre_competence')}</Typography>
                </Grid>
                
                {Array.from(Array(counter)).map((c, index) => {
                  return <Grid item xs={6} ><TextField id="outlined-uncontrolled" 
                  key={index}
                  label={t('competence')}
                  variant="outlined"
                  sx={{width : '100%'}}
                  value={nom_skill.last}  
                  onInput={(event) => { 
                    setNom_skill((prevArr) => {
                      const result = [...prevArr];
                      result[index+1] = event.target.value; 
                      return result; 
                    })}  
                }

                /></Grid>;
                })}
                

                <Grid item md={12} sx={{width : '100%'}}> 
                  <Button variant="contained" className='btn btn-ajouterCompetance' onClick={ajouterAuterCompetance}>{t('auter_comprtence')}</Button>   
                </Grid>

                <Grid item md={12} sx={{width : '100%'}}> 
                  <Button className='btn btn-right' onClick={()=> setValueTabPublier(1)} variant="contained" sx={{float : 'left'}}>{t('precedant')}</Button>
                  <Button type='submit' className='btn btn-left' variant="contained" sx={{float : 'right'}} >{t('envoyer')}</Button>   
                  

                </Grid>
              </Grid> 
          </TabPanel> 
        </form>
      </Box>
     
    </Container>
  );
}
